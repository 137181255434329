import { ResourceTypes } from '../resources.d';
import { RewardsEntities } from '../slotMachine/bonusRewards/bonusRewards.model';
import { descriptionPlateTextures } from '../slotMachine/collection/collection.model';
import { Tooltips } from '../slotMachine/tooltips/tooltips.model';
import Resources from '../utils/resources';

import { CardClass, SlotId } from './config';

const LOADER_STATIC_CARDS = Object.values(SlotId).map((slotId, index) => {
  return {
    name: slotId,
    src: `cards/cardsStatic/${index + 1}_${slotId}.json`,
  };
});

const LOADER_COLLECTION_CARDS = Object.values(CardClass).map((cardType) => {
  return {
    name: cardType,
    src: `cards/cardsCollection/${cardType}.json`,
  };
});

const LOADER_ANIMATED_SYMBOLS = [
  {
    name: 'symbolAnimated_LP1',
    src: 'cards/symbolsAnimated/LP1.json',
  },
  {
    name: 'symbolAnimated_LP2',
    src: 'cards/symbolsAnimated/LP2.json',
  },
  {
    name: 'symbolAnimated_LP3',
    src: 'cards/symbolsAnimated/LP3.json',
  },
  {
    name: 'symbolAnimated_MP1',
    src: 'cards/symbolsAnimated/MP1.json',
  },
  {
    name: 'symbolAnimated_MP2',
    src: 'cards/symbolsAnimated/MP2.json',
  },
  {
    name: 'symbolAnimated_MP3',
    src: 'cards/symbolsAnimated/MP3.json',
  },
  {
    name: 'symbolAnimated_HP1',
    src: 'cards/symbolsAnimated/HP1.json',
  },
  {
    name: 'symbolAnimated_HP2',
    src: 'cards/symbolsAnimated/HP2.json',
  },
  {
    name: 'symbolAnimated_HP3',
    src: 'cards/symbolsAnimated/HP3.json',
  },
  {
    name: 'symbolAnimated_HE1',
    src: 'cards/symbolsAnimated/HE1.json',
  },
  {
    name: 'symbolAnimated_HE2',
    src: 'cards/symbolsAnimated/HE2.json',
  },
  {
    name: 'symbolAnimated_HE3',
    src: 'cards/symbolsAnimated/HE3.json',
  },
  {
    name: 'symbolAnimated_TCM',
    src: 'cards/symbolsAnimated/TCM.json',
  },
];

export const LOADER_TEXTURES = [
  ...LOADER_STATIC_CARDS,
  ...LOADER_ANIMATED_SYMBOLS,
  ...LOADER_COLLECTION_CARDS,
  {
    name: descriptionPlateTextures,
    src: 'cards/cardsAbilities/cardsAbilities.json',
  },
  {
    name: 'bigWinMessages',
    src: '/animations/winMessages/special_messages.json',
  },
  {
    name: 'popups',
    src: '/animations/popups/pop_ups.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'featuresGameCounter',
    src: '/images/featuresGameCounter/features_game_counter.json',
  },
  {
    name: RewardsEntities.cards,
    src: '/images/bonusRewards/rewardCards.json',
  },
  {
    name: RewardsEntities.tables,
    src: '/images/bonusRewards/rewardTables.json',
  },
  {
    name: RewardsEntities.buttons,
    src: '/images/bonusRewards/radioButtons.json',
  },
  {
    name: RewardsEntities.title,
    src: '/images/bonusRewards/title.png',
  },
  {
    name: Tooltips.cards,
    src: '/images/tooltips/cards-tooltip.png',
  },
  {
    name: Tooltips.collection,
    src: '/images/tooltips/collection-tooltip.png',
  },
  {
    name: Tooltips.reward,
    src: '/images/tooltips/reward-tooltip.png',
  },
  {
    name: Tooltips.spin,
    src: '/images/tooltips/spin-tooltip.png',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: 'ambient',
    src: '/animations/ambient/ambient.json',
  },
  {
    name: 'packOpen',
    src: '/animations/pack/pack_open.json',
  },
  {
    name: 'uiAnimation',
    src: '/animations/ui/UI_elements.json',
  },
  {
    name: 'buyFeatureCoin',
    src: '/animations/buyFeature/coin_button.json',
  },
  {
    name: ResourceTypes.slotBgBase,
    src: Resources.getSource(ResourceTypes.slotBgBase),
  },
  {
    name: ResourceTypes.slotBgGold,
    src: Resources.getSource(ResourceTypes.slotBgGold),
  },
  {
    name: ResourceTypes.slotBgIce,
    src: Resources.getSource(ResourceTypes.slotBgIce),
  },
  {
    name: ResourceTypes.slotBgNeon,
    src: Resources.getSource(ResourceTypes.slotBgNeon),
  },
  {
    name: ResourceTypes.slotBgRed,
    src: Resources.getSource(ResourceTypes.slotBgRed),
  },
  {
    name: ResourceTypes.slotBgRainbow,
    src: Resources.getSource(ResourceTypes.slotBgRainbow),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureXBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtn),
  },
  {
    name: ResourceTypes.buyFeatureXBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureXBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureXBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.buyFeature,
    src: Resources.getSource(ResourceTypes.buyFeature),
  },
  {
    name: ResourceTypes.buyFeatureDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureDisable),
  },
  {
    name: ResourceTypes.buyFeatureHover,
    src: Resources.getSource(ResourceTypes.buyFeatureHover),
  },
  {
    name: ResourceTypes.buyFeaturePressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePressed),
  },
  {
    name: ResourceTypes.buyFeaturePopupRage,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupRage),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.buyFeaturePopupBackplate,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupBackplate),
  },
  {
    name: ResourceTypes.gameLogo,
    src: Resources.getSource(ResourceTypes.gameLogo),
  },
  {
    name: 'cardBackSprite',
    src: '/cards/back/back.json',
  },
  {
    name: 'cardAbilities',
    src: '/cards/abilities/abilities.json',
  },
  {
    name: 'card_frame_open',
    src: '/cards/cardFrameOpen/card_frame_open.json',
  },
  {
    name: 'card_frame_backlight',
    src: '/cards/cardFrameBacklight/card_frame_backlight.json',
  },
  {
    name: 'card_frame_idle',
    src: '/cards/cardFrameIdle/card_frame_idle.json',
  },
  {
    name: 'card_frame_disappear',
    src: '/cards/cardFrameDisappear/card_frame_disappear.json',
  },
  {
    name: 'phoenixMovie',
    src: '/movie/phoenixAnticipation.mp4',
  },
  {
    name: ResourceTypes.newIcon,
    src: Resources.getSource(ResourceTypes.newIcon),
  },
  {
    name: 'abilities_animated',
    src: '/animations/abilities/abilities_animated.json',
  },
];
