import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { gameRules1, gameRules2 } from '../../config/gameRules';
import { setSlotConfig, setUserBalance } from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation();
  const userBalance = useReactiveVar(setUserBalance);
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  const betSettings = getBetsSetting();
  const { currency } = userBalance.balance;
  const minBet = formatNumber({ currency: currency, value: betSettings.minBetCount, showCurrency: false });
  const maxBet = formatNumber({ currency: currency, value: betSettings.maxBetCount, showCurrency: false });
  const version = window.__ENV__?.APP_VERSION ?? 'develop';

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoGameRulesTitle')}</h1>
      <p className={`${styles['p']} ${styles['left']}`}>
        {gameRules1
          .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
          .map((v) => (
            <div key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} className="game-rule" />
          ))}
      </p>
      <p className={`${styles['p']} ${styles['left']}`}>
        {gameRules2
          .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
          .map((v) => (
            <div key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} />
          ))}
      </p>
      <p className={`${styles['p']} ${styles['left']}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules2', {
              minBet,
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules3', {
              maxBet,
            }),
          }}
        />
      </p>
      <p className={`${styles['p']} ${styles['left']}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules4', {
              rtp: '96.66%',
            }),
          }}
        />
        {setSlotConfig().isBuyFeatureEnabled && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: t('infoGameRules5', {
                  rtp: '96.71%',
                }),
              }}
            />
          </>
        )}
        {/* {isBuyFeatureEnabled ? (
          <div
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules5', {
                rtp: '96.66%',
              }),
            }}
          />
        ) : (
          ''
        )} */}
      </p>
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRulesVersion', {
            version,
          }),
        }}
      />
    </div>
  );
};

export default GameRules;
