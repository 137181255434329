import { EventTypes, GameMode } from '../../global.d';
import { setCurrentBonusId } from '../../gql/cache';
import type { BigWinContainer } from '../bigWinPresentation/bigWinContainer';
import type { CardsContainer } from '../cards/cardsContainer';
import { ViewContainer } from '../components/ViewContainer';
import { SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import type { IGameContainer } from '../d';
import Logo from '../logo/logo';
import type { RewardsPopup } from '../popups/rewardsPopup/rewardsPopup';
import type WinCountUpMessage from '../winAnimations/winCountUpMessage';

class GameView extends ViewContainer {
  public cardsContainer: CardsContainer;

  public bigWinContainer: BigWinContainer;

  public winCountUpMessage: WinCountUpMessage;

  public gameLogo: Logo;

  public rewardsPopup: RewardsPopup;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.gameLogo = new Logo();
    this.cardsContainer = props.cardsContainer;
    this.rewardsPopup = props.rewardsPopup;
    this.bigWinContainer = props.bigWinContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.addChild(this.gameLogo);
    this.addChild(this.cardsContainer);
    this.addChild(this.rewardsPopup);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.bigWinContainer);

    setTimeout(() => {
      if (setCurrentBonusId()) {
        eventManager.emit(EventTypes.TOGGLE_SPIN);
      }
    }, 500);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.FREE_PACKS:
      case GameMode.MASTER_PACKS:
        this.handleFreeSpinsChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  private handleBaseModeChanges(): void {
    // handleBaseModeChanges
  }

  private handleFreeSpinsChanges(): void {
    // handleFreeSpinsChanges
  }

  private resizeGameContainer(_width: number, _height: number): void {
    this.gameLogo.x = SLOTS_CONTAINER_WIDTH / 2 - this.gameLogo.width / 2;
    this.gameLogo.y = 32;
    this.cardsContainer.x = SLOTS_CONTAINER_WIDTH / 2;
    this.cardsContainer.y = 570;

    this.scale.set(_width / SLOTS_CONTAINER_WIDTH);
  }
}

export default GameView;
