import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setFreePacksCount } from '../../gql/cache';
import { calcPercentage } from '../../utils';
import type { IEntryAnimation } from '../animations/d';
import { eventManager } from '../config';

import { ControlButton } from '.';

class FreePacksCount extends ControlButton {
  private count: PIXI.Text;

  private text: PIXI.Text;

  private animation: Spine;

  constructor() {
    super('');
    this.interactive = false;
    this.count = this.initCount();
    this.text = this.initText();
    this.animation = this.initAnimation();
    this.btn.texture = PIXI.Loader.shared.resources['featuresGameCounter']!.spritesheet?.textures['free_card_counter']!;
    this.btn.interactive = false;
    this.btn.visible = false;
    this.visible = false;
    this.btn.addChild(this.count);
    this.btn.addChild(this.text);
    this.addChild(this.animation);
    this.initSubscriptions();
    if (setFreePacksCount()) {
      this.show();
    }
  }

  private initAnimation(): Spine {
    const animation = new Spine(PIXI.Loader.shared.resources!['uiAnimation']!.spineData!);
    animation.name = 'cardBacklightAnimation';
    animation.state.addListener({
      complete: (entry: IEntryAnimation) => {
        if (entry.animation.name === 'free_packs_counter_appear') {
          this.count.text = setFreePacksCount();
          this.btn.visible = true;
        }
        if (entry.animation.name === 'free_packs_counter_disappear') {
          this.btn.visible = false;
        }
      },
    });

    return animation;
  }

  private setAnimationByGameMode(gameMode: GameMode): void {
    switch (gameMode) {
      // case GameMode.BASE_GAME:
      //   this.animation.state.setAnimation(0, 'master_packs_counter_disappear', false);
      //   break;
      // case GameMode.MASTER_PACKS:
      //   this.animation.state.setAnimation(0, 'master_packs_counter_appear', false);
      //   break;
      default:
        break;
    }
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.setAnimationByGameMode(_settings.mode);
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.ADD_WIN_FREE_PACKS, () => {
      this.show();
    });
    eventManager.on(EventTypes.UPDATE_WIN_FREE_PACKS, () => {
      this.updateCount();
    });
  };

  private show() {
    this.visible = true;
    this.animation.state.setAnimation(0, 'free_packs_counter_appear', false);
  }

  private hide() {
    this.animation.state.setAnimation(0, 'free_packs_counter_disappear', false);
  }

  private updateCount() {
    if (setFreePacksCount() === 0) {
      this.hide();
    }
    this.count.text = setFreePacksCount();
  }

  private initCount = (): PIXI.Text => {
    const count = new PIXI.Text('0', {
      fontFamily: 'NotoSans-SemiCondensedBold',
      fontSize: 32,
      fill: '#ffffff',
      whiteSpace: 'normal',
      fontWeight: '700',
      align: 'center',
      miterLimit: 5,
      padding: 5,
      stroke: '#000000',
      strokeThickness: 4,
    });
    count.anchor.set(0.5);
    count.y = -14;
    count.x = 0;

    return count;
  };

  private initText = (): PIXI.Text => {
    const count = new PIXI.Text('FREE', {
      fontFamily: 'NotoSans-SemiCondensedBold',
      fontSize: 20,
      fill: '#B1FAFF',
      whiteSpace: 'normal',
      fontWeight: '700',
      align: 'center',
      miterLimit: 5,
      padding: 5,
      stroke: '#000000',
      strokeThickness: 4,
    });
    count.anchor.set(0.5);
    count.y = 20;
    count.x = 0;

    return count;
  };

  public override setSize(): void {}

  public override handlePosition(): void {
    this.setScale();
    this.btn.scale.set(calcPercentage(this.applicationSize.width, 8.2) / 150);
    const x = calcPercentage(this.applicationSize.width, 69);
    const y = calcPercentage(this.applicationSize.height, 100) - this.btn.height / 2.5;

    this.x = x;
    this.y = y;
  }

  private setScale = () => {
    this.animation.scale.set(this.btn.scale.x, this.btn.scale.y);
  };
}

export default FreePacksCount;
