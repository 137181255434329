import { BuyFeatureEnterProps, EventTypes, GameMode, UserBonus } from '../../global.d';
import { setCoinAmount, setCoinValue, setCurrentBonusId, setIsPopupOpened } from '../../gql/cache';
import client from '../../gql/client';
import { buyBonusGql } from '../../gql/mutation';
import { eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import type { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class BuyFeatureController extends BaseController {
  public override gameMode = GameMode.BUY_FEATURE;

  public bonusId: string | null = null;

  public static override the = new BuyFeatureController();

  private constructor() {
    super();
  }

  override enterController(prevGameMode: GameMode, props: BuyFeatureEnterProps): void {
    if (prevGameMode !== GameMode.BASE_GAME) throw new Error('CANT BUY FEATURE NOT IN BASE MODE');
    client
      .mutate<{
        buyBonus: {
          bonus: UserBonus;
          bonusId: string;
          id: string;
          balance: { amount: number; currency: string };
        };
      }>({
        mutation: buyBonusGql,
        variables: {
          input: {
            id: props?.bonusId,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      })
      .then((res) => {
        const { id, balance } = res.data!.buyBonus;
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, balance);
        PopupController.the.closeCurrentPopup();
        setIsPopupOpened(false);
        setCurrentBonusId(id);
        Logic.the.changeGameMode(GameMode.BASE_GAME);
        eventManager.emit(EventTypes.TOGGLE_SPIN);
      });
  }

  public override exitController(_nextGameMode: GameMode): void {}

  public override enterIdleState(_prevState: States): void {
    eventManager.emit(EventTypes.TOGGLE_SPIN);
  }
}
