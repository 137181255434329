import { Container, Loader, Sprite } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../config';
import { setIsModalVisible, setIsOpenMenuModal, setIsPopupOpened } from '../gql/cache';

import CloseBtn from './bonusRewards/closeBtn';
import { Title } from './bonusRewards/title';

export class ModalService {
  static _instanceCache: ModalService;

  protected parentModals = ['Collection', 'BonusRewards'];

  static get the() {
    if (!this._instanceCache) {
      this._instanceCache = new this();
    }

    return this._instanceCache;
  }
  private openModals: Container[] = [];

  public get isModalVisible() {
    return this.modalContainer.children.length;
  }
  private modalContainer = new Container();

  public open(component: Container, option: { title: string }) {
    if (setIsPopupOpened() || setIsOpenMenuModal().isOpen) return;
    const alreadyOpen = this.openModals.find((modal) => modal.name && modal.name === component.name);
    if (this.openModals.length) {
      const isNewParentModal = this.openModals.find(
        (modal) => modal.name && this.parentModals.find((e) => e === component.name),
      );
      if (isNewParentModal) {
        this.clear();
      }
    }
    if (alreadyOpen) return;
    setIsModalVisible(true);
    const subModalContainer = new Container();
    subModalContainer.name = component.name;
    const closeBtn = new CloseBtn(() => {
      AudioApi.play({ type: ISongs.SFX_UI_Close });
      this.hide();
    });
    const backdrop = new Sprite(Loader.shared.resources['backdrop']?.texture);
    backdrop.zIndex = 2;
    this.modalContainer.visible = true;
    this.modalContainer.interactive = true;
    subModalContainer.addChild(backdrop, closeBtn, new Title(option.title), component);
    this.modalContainer.addChild(subModalContainer);
    this.openModals.push(subModalContainer);
    this.setBackdropClickZone(backdrop);

    // Workaround need to render controlButton, problem with realization
    window.dispatchEvent(new Event('resize'));
    // this.alignToCenterBody(component);
  }

  public setBackdropClickZone(backdrop: Container): void {
    backdrop.interactive = true;
    backdrop.on('pointertap', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Close });
      this.hide();
    });
  }

  public hide(): void {
    setIsModalVisible(false);
    const subModalContainer = this.openModals.pop();
    if (!subModalContainer) return;
    subModalContainer.children.forEach((child) => {
      child.destroy({ children: true, baseTexture: true });
    });
    subModalContainer.destroy({ children: true, baseTexture: true });
    this.modalContainer.removeChild(subModalContainer);
  }

  public clear(): void {
    this.openModals.forEach(() => {
      this.hide();
    });
  }

  public registerModal(container: Container) {
    this.modalContainer.zIndex = 3;
    container.addChild(this.modalContainer);
  }

  private alignToCenterBody(element: Container): void {
    element.x = (window.innerWidth - element.width * this.modalContainer.scale.x) / 2 / this.modalContainer.scale.x;
    element.y = (window.innerHeight - element.height * this.modalContainer.scale.y) / 2 / this.modalContainer.scale.y;
  }
}
