import { Container, Graphics, InteractionEvent, Sprite, Texture } from 'pixi.js';

const THUMB_OFFSET = 100;
const THUMB_WIDTH = 15;
const SCROLLBAR_WIDTH = 6;
const RIGHT_CONTENT_OFFSET = 30;
export class ScrollBox extends Container {
  private draggingThumb = false;
  private lastThumbY = 0;

  private draggingContainer = false;
  private lastPositionY = 0;
  private contentMask = new Graphics();
  private scrollbar = new Graphics();

  private thumb = new Container();
  constructor(private content: Container, private boxHeight = 600) {
    super();

    this.contentMask = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, this.content.width + THUMB_OFFSET + THUMB_WIDTH + RIGHT_CONTENT_OFFSET, boxHeight)
      .endFill();

    this.scrollbar = new Graphics()
      .beginFill(0xff7a00)
      .drawRect(this.content.width + THUMB_OFFSET, 0, SCROLLBAR_WIDTH, boxHeight)
      .endFill();

    this.thumb = this.getThumb();

    this.mask = this.contentMask;
    this.addChild(this.contentMask, this.getContentContainer(this.content), this.scrollbar, this.thumb);
  }

  private getContentContainer(content: Container): Container {
    const container = new Container();
    const transparentSprite = new Sprite(Texture.EMPTY);

    container.interactive = true;
    content.interactive = true;
    transparentSprite.width = content.width;
    transparentSprite.height = content.height;
    transparentSprite.zIndex = 1;

    container.on('pointerdown', this.onDragStart.bind(this));
    container.on('pointerup', this.onDragEnd.bind(this));
    container.on('pointerupoutside', this.onDragEnd.bind(this));
    container.on('pointermove', this.onDragMove.bind(this));

    document.addEventListener('wheel', ({ x, y, deltaY }) => {
      if (this.children.length) {
        const { x: contentX, y: contentY } = this.getGlobalPosition();
        const isInsideContainer =
          x >= contentX && x <= contentX + container.width && y >= contentY && y <= contentY + this.boxHeight;

        if (!isInsideContainer) return;

        const isScrollInContainerLimits =
          this.content.y - deltaY < 0 && this.content.y - deltaY > this.boxHeight - content.height;

        const isUp = deltaY < 0;

        if (isScrollInContainerLimits) {
          this.content.y -= deltaY;
        } else {
          this.content.y = isUp ? 0 : this.boxHeight - content.height;
        }

        this.updateHandlePosition();
      } else {
        this.thumb.removeAllListeners();
        container.removeAllListeners();
        this.removeAllListeners();
      }
    });

    container.addChild(transparentSprite, content);

    return container;
  }

  private onDragStart(event: InteractionEvent): void {
    this.draggingContainer = true;
    this.lastPositionY = event.data.global.y - this.content.y;
  }

  private onDragEnd(): void {
    this.draggingContainer = false;
  }

  private onDragMove(event: InteractionEvent): void {
    if (!this.draggingContainer) return;

    const newY = event.data.global.y - this.lastPositionY;
    const minY = 0;
    const maxY = this.boxHeight - this.content.height;

    this.content.y = Math.min(Math.max(newY, maxY), minY);

    this.updateHandlePosition();
  }

  private updateHandlePosition(): void {
    const scrollPercentage = this.content.y / (this.boxHeight - this.content.height);

    const minY = this.scrollbar.y;
    const maxY = this.scrollbar.y + this.scrollbar.height - this.thumb.height;
    this.thumb.y = minY + (maxY - minY) * scrollPercentage;
  }

  private getThumb(): Container {
    const thumbHeight = (this.boxHeight * this.boxHeight) / this.content.height;
    const thumbContainer = new Container();
    const transparentSprite = new Sprite(Texture.EMPTY);
    thumbContainer.interactive = true;
    thumbContainer.buttonMode = true;
    transparentSprite.width = THUMB_WIDTH * 2;
    transparentSprite.height = thumbHeight;
    thumbContainer.position.set(this.content.width + THUMB_OFFSET - (transparentSprite.width - SCROLLBAR_WIDTH) / 2, 0);

    const thumb = new Graphics()
      .beginFill(0xffc200)
      .drawRoundedRect((transparentSprite.width - THUMB_WIDTH) / 2, 0, THUMB_WIDTH, thumbHeight, 20)
      .endFill();

    thumbContainer.addChild(transparentSprite, thumb);

    thumbContainer.on('pointerdown', this.onThumbDragStart.bind(this));
    thumbContainer.on('pointerup', this.onThumbDragEnd.bind(this));
    thumbContainer.on('pointerupoutside', this.onThumbDragEnd.bind(this));
    thumbContainer.on('pointermove', this.onThumbDragMove.bind(this));

    return thumbContainer;
  }

  private onThumbDragStart(event: InteractionEvent): void {
    this.draggingThumb = true;
    this.lastThumbY = event.data.global.y - this.thumb.y;
  }

  private onThumbDragEnd(): void {
    this.draggingThumb = false;
  }

  private onThumbDragMove(event: InteractionEvent): void {
    if (!this.draggingThumb) return;

    // Calculate the new position of the handle based on the mouse movement
    const newY = event.data.global.y - this.lastThumbY;
    const minY = this.scrollbar.y;
    const maxY = this.scrollbar.y + this.scrollbar.height - this.thumb.height;
    this.thumb.y = Math.max(Math.min(newY, maxY), minY);

    // Calculate the scroll percentage based on the thumb's position
    const scrollPercentage = (this.thumb.y - minY) / (maxY - minY);

    // Update the container's position to scroll the content
    const minYContainer = this.contentMask.y;
    const maxYContainer = this.contentMask.y + this.contentMask.height - this.content.height;
    this.content.y = minYContainer + (maxYContainer - minYContainer) * scrollPercentage;
  }
}
