export * from './types';
export enum States {
  INIT = 'INIT',
  INTRO = 'INTRO',
  BROKEN_GAME = 'BROKEN_GAME',
  IDLE = 'IDLE',
  BEFORE_WIN = 'BEFORE_WIN',
  APPEND_ABILITIES = 'APPEND_ABILITIES',
  WIN_PRESENTATION = 'WIN_PRESENTATION',
  AFTER_WIN = 'AFTER_WIN',
  JINGLE = 'JINGLE',
  TRANSITION = 'TRANSITION',
  PACK_OPEN = 'PACK_OPEN',
}
