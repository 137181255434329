import i18n from 'i18next';
import { ITextStyle, Sprite, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setBonuses } from '../../../gql/cache';
import { ResourceTypes } from '../../../resources.d';
import { titleBaseTextStyle, totalCostBaseTextStyle } from '../../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { PopupTypes, SLOTS_CONTAINER_WIDTH, eventManager } from '../../config';
import type { BuyFeatureConfirmProps } from '../../d';
import { PopupController } from '../PopupController';
import { Popup } from '../popup';

import BuyFeaturePopupConfirmBackgroundStatic from './buyFeaturePopupConfirmBackgroundStatic';

class BuyFeaturePopupConfirm extends Popup {
  private popupBg!: BuyFeaturePopupConfirmBackgroundStatic;

  private coinAmount: number;

  private titleText: TextField;

  private titleFeatureText: TextField;

  private totalCostText: TextField;

  private totalCostValue: TextField;

  private betAmountBackplate: Sprite;

  private okBtn: Sprite;

  private cancelBtn: Sprite;

  constructor() {
    super();
    this.visible = false;
    this.coinAmount = 1;
    this.initBackground();
    this.titleText = this.initTitle();
    this.titleFeatureText = this.initTitleFeature();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.init();
    this.x = SLOTS_CONTAINER_WIDTH / 2 - this.popupBg.width / 2;
    this.y = 300;
  }

  public override show(props: BuyFeatureConfirmProps): void {
    super.show();
    const { coinAmount, totalCost } = props;
    this.titleText.setText(i18n.t<string>('buyFeatureConfirmTitle'));
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText.getText());
    this.addChild(this.titleFeatureText.getText());
    this.addChild(this.totalCostText.getText());
    this.addChild(this.totalCostValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initBackground(): void {
    this.popupBg = new BuyFeaturePopupConfirmBackgroundStatic();
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureConfirmTitle'),
      1300,
      300,
      totalCostBaseTextStyle as Partial<ITextStyle>,
    );
    title.text.y = 70;
    title.text.x = title.width / 2 + (this.popupBg.width - title.width) / 2;
    title.text.anchor.set(0.5, 0.5);
    return title;
  }

  private initTitleFeature(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureConfirmTitleFeature'),
      1300,
      300,
      titleBaseTextStyle as Partial<ITextStyle>,
    );
    title.text.y = 150;
    title.text.x = title.width / 2 + (this.popupBg.width - title.width) / 2;
    title.text.anchor.set(0.5, 0.5);
    return title;
  }

  private initTotalCostText(): TextField {
    const titleCost = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      1300,
      300,
      totalCostBaseTextStyle as Partial<ITextStyle>,
    );
    titleCost.text.y = 200;
    titleCost.text.x = titleCost.width / 2 + (this.popupBg.width - titleCost.width) / 2;

    titleCost.text.anchor.set(0.5, 0);

    return titleCost;
  }

  private initTotalCostValue(): TextField {
    const totalCostValue = new TextField('0', 500, 100, titleBaseTextStyle as Partial<ITextStyle>);
    totalCostValue.text.y = 240;
    totalCostValue.text.x = totalCostValue.text.x =
      totalCostValue.width / 2 + (this.popupBg.width - totalCostValue.width) / 2;
    totalCostValue.text.anchor.set(0.5, 0);
    return totalCostValue;
  }

  private initBetAmountBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    input.y = 560;
    input.x = 555;
    input.width = 460;
    input.height = 120;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
      onTouchStart: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
    });
    cancelBtn.anchor.set(0.5, 0.5);
    cancelBtn.width = 100;
    cancelBtn.height = 60;
    cancelBtn.y = 335;
    cancelBtn.x = isMobile.any ? 180 : 169;
    return cancelBtn;
  }

  private initOkBtn(): Sprite {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        AudioApi.play({ type: ISongs.SFX_UI_General });
        this.startBuyFeature();
      },
      onTouchStart: () => {
        AudioApi.play({ type: ISongs.SFX_UI_General });
        this.startBuyFeature();
      },
    });

    okBtn.anchor.set(0.5, 0.5);
    okBtn.y = 335;
    okBtn.x = 335;
    okBtn.width = 100;
    okBtn.height = 60;
    return okBtn;
  }

  private startBuyFeature(): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, setBonuses()[0]?.id);
  }

  public handleClose(_closeBuyFeature?: boolean): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    PopupController.the.openPopup(PopupTypes.BUY_FEATURE);
  }
}

export default BuyFeaturePopupConfirm;
