import MultiStyleText from 'pixi-multistyle-text';
import { Container, Loader, Sprite } from 'pixi.js';

import type { CardLevel } from '../../config';
import { CardClass } from '../../config';
import { EventTypes } from '../../global.d';
import { setEnabledCards } from '../../gql/cache';
import i18n from '../../i18next';
import { ModalService } from '../ModalService';
import { levelColors, levelNames } from '../collection/collection.model';
import { eventManager } from '../config';
import { BonusRewardsBtn } from '../controlButtons/bonusRewardsBtn';

import { RewardsEntities, rewards } from './bonusRewards.model';

type DisplayType = 'all' | 'card' | 'table';
export class RewardInfo extends Container {
  public override name = 'RewardInfo';
  public override interactive = true;
  constructor() {
    super();
    this.zIndex = 2;

    this.addChild(this.getBonusRewardsBtn());

    this.on('click', () => {
      ModalService.the.hide();
    });
    this.on('touchstart', () => {
      ModalService.the.hide();
    });
  }

  viewReward(rewardName: CardLevel, screenWidth: number, screenHeight: number): void {
    const rewardCard = this.getRewardCard(rewardName);
    const rewardTable = this.getRewardTable(rewardName);
    const rewardDescription = this.getDescription(rewardName);
    let display: DisplayType = 'all';

    this.addChild(rewardDescription);
    if (rewards.some(({ card, table }) => card === rewardName && table === rewardName)) {
      this.addChild(rewardTable, rewardCard);
    } else if (rewards.some(({ card }) => card === rewardName)) {
      display = 'card';
      this.addChild(rewardCard);
    } else if (rewards.some(({ table }) => table === rewardName)) {
      display = 'table';
      this.addChild(rewardTable);
    }

    this.getRewardContainerSizes(rewardCard, rewardTable, rewardDescription, screenWidth, screenHeight, display);

    eventManager.on(EventTypes.CHILD_MODAL_RESIZE, (width, height) => {
      this.getRewardContainerSizes(rewardCard, rewardTable, rewardDescription, width, height, display);
    });
  }

  private getBonusRewardsBtn(): Container {
    const bonusRewardsBtn = new BonusRewardsBtn(() => ModalService.the.hide());

    // Workaround need to render controlButton, problem with realization

    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);

    return bonusRewardsBtn;
  }
  private getRewardContainerSizes(
    rewardCard: Container,
    rewardTable: Container,
    rewardDescription: Container,
    width: number,
    height: number,
    display: DisplayType,
  ): void {
    if (this.children.length) {
      const scale = Math.max(height / 700, 0.7);
      rewardCard.scale.set(scale);
      rewardDescription.scale.set(scale);
      rewardTable.scale.set(scale);
      if (display === 'all') {
        rewardCard.x = width / 2 - rewardCard.width / 2;
        rewardCard.y = height / 2 - rewardCard.height / 2;
        rewardDescription.x = rewardCard.x - rewardCard.width / 1.5 - 32;
        rewardTable.x = rewardCard.x + rewardCard.width / 2;
        rewardDescription.y = height / 2 - rewardDescription.height / 2;
        rewardTable.y = height / 2 - rewardTable.height / 2;
      } else if (display === 'table') {
        rewardTable.x = width / 2 - rewardTable.width / 2;
        rewardTable.y = height / 2 - rewardTable.height / 2;
        rewardDescription.x = rewardTable.x - rewardTable.width / 1.5 - 32;
        rewardDescription.y = height / 2 - rewardDescription.height / 2;
      } else {
        rewardCard.x = width / 2 - rewardCard.width / 2;
        rewardCard.y = height / 2 - rewardCard.height / 2;
        rewardDescription.x = rewardCard.x - rewardCard.width / 1.5 - 32;
        rewardDescription.y = height / 2 - rewardDescription.height / 2;
      }
    } else {
      eventManager.removeListener(EventTypes.CHILD_MODAL_RESIZE);
      this.removeAllListeners();
    }
  }
  private getRewardCard(name: CardLevel): Container {
    const card = new Sprite(Loader.shared.resources[RewardsEntities.cards]?.spritesheet?.textures[`${name}-opened`]);
    card.name = `card_${name}`;
    card.zIndex = 1;
    card.width = 516;
    card.height = 684;

    return card;
  }
  private getRewardTable(name: CardLevel): Container {
    const table = new Sprite(Loader.shared.resources[RewardsEntities.tables]?.spritesheet?.textures[`${name}-opened`]);
    table.name = `table_${name}`;

    table.width = 640;
    table.height = 448;

    return table;
  }

  getDescription(rewardName: CardLevel): Container {
    const unlockedCards = setEnabledCards();
    const numberOfAllLevelCard = Object.values(CardClass).length;
    const numberOfUnlockedLevelCard = unlockedCards.filter((slotId) => slotId.includes(rewardName)).length;

    const description = new MultiStyleText(
      i18n.t<string>('rewardDescription', {
        name: levelNames[rewardName],
        count: numberOfUnlockedLevelCard,
        total: numberOfAllLevelCard,
      }),
      {
        default: {
          fill: 'white',
          fontSize: 18,
          whiteSpace: 'normal',
          fontWeight: '700',
        },
        name: { fill: levelColors[rewardName] },
        total: { fill: '#777', fontSize: 19 },
      },
    );

    return description;
  }
}
