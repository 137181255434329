import { Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { CardLevel, ISongs } from '../../config';
import { EventTypes, type GameMode } from '../../global.d';
import { setIsOpenMenuModal, setIsPopupOpened, setUnlockedBackgrounds } from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { ResourceTypes } from '../../resources.d';
import { calcPercentage, canPressSpin } from '../../utils';
import { eventManager } from '../config';
import { TimeActions } from '../tooltips/timeActions';
import { Tooltip } from '../tooltips/tooltip';
import { Tooltips } from '../tooltips/tooltips.model';

import { ControlButton } from './index';

export class BonusRewardsBtn extends ControlButton {
  public newIcon = this.getNewLabel();
  private tooltip = this.getTooltip();

  private isSlotBusy: boolean;

  private isAutoSpins = false;
  private prevUnlockedRewards: CardLevel[] = [];

  constructor(private onClick: (newRewards: CardLevel[]) => void = () => {}) {
    super('bonus-rewards');
    this.interactive = !this.isDisabled;
    this.isSlotBusy = false;
    this.initSubscriptions();
    this.handleDisable();
    this.btn.addChild(this.newIcon, this.tooltip);
    this.hideNewLabel();
    this.prevUnlockedRewards = setUnlockedBackgrounds();
    TimeActions.the.executeAfterNewReward(() => this.tooltip.show());

    this.on('pointertap', () => this.handleClick());

    eventManager.on(EventTypes.UPDATE_BONUS_REWARDS, () => {
      if (this.children.length) {
        this.showNewLabel();
      }
    });
    eventManager.addListener(EventTypes.CHANGE_STATE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });

    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
  }

  private handleClick(): void {
    if (this.isDisabled || setIsPopupOpened() || setIsOpenMenuModal().isOpen) return;
    AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
    const newRewards = setUnlockedBackgrounds().filter((level) => !this.prevUnlockedRewards.includes(level));
    this.prevUnlockedRewards = setUnlockedBackgrounds();
    this.hideNewLabel();
    this.tooltip.hide();
    this.onClick(newRewards);
  }

  private showNewLabel(): void {
    this.newIcon.visible = true;
  }

  private hideNewLabel(): void {
    this.newIcon.visible = false;
  }

  private getNewLabel(): Sprite {
    const newIcon = new Sprite(Texture.from(ResourceTypes.newIcon));
    newIcon.y = -40;

    return newIcon;
  }

  private getTooltip(): Tooltip {
    const tooltip = new Tooltip(Tooltips.reward, 'tooltipReward');
    tooltip.position.set(-45, -tooltip.height - 40);

    return tooltip;
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {}

  private initSubscriptions = (): void => {};

  private handleDisable = (): void => {
    if (this.children.length) {
      const isDisable = !canPressSpin({
        gameMode: Logic.the.controller.gameMode,
        slotState: Logic.the.state.name,
        isSlotBusy: this.isSlotBusy,
        isPopupOpened: setIsPopupOpened(),
      });

      this.setDisable(isDisable || this.isAutoSpins || Logic.the.state.name !== States.IDLE);
    }
  };

  public override handlePosition(): void {
    if (this.children.length) {
      let x = calcPercentage(this.applicationSize.width, 34);
      let y = calcPercentage(this.applicationSize.height, 95);
      this.setSize(calcPercentage(this.applicationSize.width, 4));

      if (this.isPortraitMode) {
        this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 20));
        x = calcPercentage(this.applicationSize.width, 87.5);
        y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 25);
      }
      if (this.isLandscapeMode) {
        this.setSize(calcPercentage(this.applicationSize.height, 8));
        x = calcPercentage(this.applicationSize.width, 34);
        if (this.btn.scale.x > 2 && this.applicationSize.width / this.applicationSize.height < 1.3) {
          x = calcPercentage(this.applicationSize.width, 37);
        }
        y = calcPercentage(this.applicationSize.height, 87.5);
      }
      this.x = x;
      this.y = y;
    }
  }
}
