import { Container, Loader, Sprite, TextStyle } from 'pixi.js';

import { cardAbilitiesLabelStyle, cardAbilitiesValueTextStyle } from '../cards/textStyles';
import { TextField } from '../components/TextField';
import type { ICardAbility } from '../d';

import { abilitiesPreferences } from './collection.model';

export class Ability extends Container {
  constructor(private config: ICardAbility[], private ratio = 1) {
    super();
    this.addChild(this.initCardAbilities(config));
  }

  private initCardAbilities(abilities: ICardAbility[]): Container {
    const container = new Container();
    abilities?.forEach(({ pattern, value }, index) => {
      const abilityValue = new TextField(
        abilitiesPreferences[pattern].prefix + value,
        270,
        100,
        cardAbilitiesValueTextStyle,
      );

      const label = new TextField(
        abilitiesPreferences[pattern].label,
        270,
        100,
        new TextStyle({ ...cardAbilitiesLabelStyle, fill: abilitiesPreferences[pattern].color }),
      );
      const abilityIcon = new Sprite(Loader.shared.resources['cardAbilities']!.spritesheet!.textures[`${pattern}`]);

      abilityValue.text.anchor.set(0.5);
      label.text.anchor.set(0.5);
      label.text.y = 27;
      abilityIcon.anchor.set(0.5);
      abilityIcon.scale.set(this.ratio);
      abilityIcon.x = index * abilityIcon.width;
      abilityIcon.addChild(abilityValue.getText(), label.getText());
      container.addChild(abilityIcon);
      container.x = abilityIcon.width / 2;
      container.y = abilityIcon.height / 2;
    });
    return container;
  }
}
