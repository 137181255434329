import {
  BehaviorSubject,
  Observable,
  Subject,
  delay,
  filter,
  interval,
  merge,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

export class TimeActions {
  static _instanceCache: TimeActions;

  static get the() {
    if (!this._instanceCache) {
      this._instanceCache = new this();
    }

    return this._instanceCache;
  }
  private count = 0;
  private count$ = new BehaviorSubject<number>(0);
  private resetTimer$ = new Subject<void>();
  private packOpenTrigger$ = new Subject<void>();
  private flipAllCardTrigger$ = new Subject<void>();
  private flipCardTrigger$ = new Subject<void>();
  private newCardAppear$ = new Subject<void>();
  private newRewardAchieved$ = new Subject<void>();

  public incrementFlipBuyPack(): void {
    this.count$.next(++this.count);
  }
  public getNewCard(): void {
    this.newCardAppear$.next();
  }
  public achieveNewReward(): void {
    this.newRewardAchieved$.next();
  }
  public triggerFlipAllCard(): void {
    this.flipAllCardTrigger$.next();
  }
  public triggerFlipCard(): void {
    this.flipCardTrigger$.next();
  }
  public triggerBuyPack(): void {
    this.packOpenTrigger$.next();
  }

  public resetTimers(): void {
    this.resetTimer$.next();
  }

  executeAfterNewReward(fn = () => {}): void {
    this.newRewardAchieved$
      .pipe(
        delay(100),
        switchMap(() => merge(this.flipAllCardTrigger$, this.packOpenTrigger$)),
        switchMap(() => interval(200).pipe(take(1), takeUntil(this.resetTimer$))),
        tap(() => fn()),
        take(1),
      )
      .subscribe();
  }

  public executeAfterNewCard(afterTimeSec: number, fn = () => {}): void {
    this.newCardAppear$
      .pipe(
        switchMap(() => merge(this.flipAllCardTrigger$, this.packOpenTrigger$)),
        switchMap(() =>
          interval(1000).pipe(
            filter((time) => afterTimeSec - 1 === time),
            tap(() => fn()),
            take(1),
            takeUntil(this.resetTimer$),
          ),
        ),
        take(1),
      )
      .subscribe();
  }

  public executeBeforeFlipCard(triggerCount: number, afterTimeSec: number, fn = () => {}): void {
    this.executeBefore(triggerCount, afterTimeSec, fn, this.flipAllCardTrigger$);
  }
  public executeBeforeBuyPack(triggerCount: number, afterTimeSec: number, fn = () => {}): void {
    this.executeBefore(triggerCount, afterTimeSec, fn, this.packOpenTrigger$);
  }

  private executeBefore(triggerCount: number, afterTimeSec: number, fn = () => {}, trigger: Observable<void>): void {
    this.count$
      .pipe(
        switchMap(() => trigger),
        take(triggerCount),
        switchMap(() =>
          interval(1000).pipe(
            filter((time) => afterTimeSec - 1 === time),
            tap(() => fn()),
            take(1),
            takeUntil(this.flipCardTrigger$),
            takeUntil(this.resetTimer$),
          ),
        ),
      )
      .subscribe();
  }
}
