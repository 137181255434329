import { Subject } from 'rxjs';

import type { CardAbilitiesPattern } from '../../../config';

export interface RewardAction {
  index: number;
  pattern: CardAbilitiesPattern;
}

export class RewardsActions {
  static _instanceCache: RewardsActions;

  static get the() {
    if (!this._instanceCache) {
      this._instanceCache = new this();
    }

    return this._instanceCache;
  }

  private showRewardsPopup$ = new Subject<RewardAction>();
  public rewardsPopupObservable = this.showRewardsPopup$.asObservable();

  private destroy$ = new Subject();
  public destroyObservable = this.destroy$.asObservable();

  public emitEvent(index: number, pattern: CardAbilitiesPattern): void {
    this.showRewardsPopup$.next({ index, pattern });
  }

  public unsubscribe(): void {
    this.destroy$.next(true);
  }
}
