import { Container, Graphics, Loader, Sprite, Texture } from 'pixi.js';

import type { CardClass, CardLevel, SlotId } from '../../config';
import { setEnabledCards } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';

import { Ability } from './ability';
import { cardAbilities } from './collection.model';

const DEFAULT_CARD_WIDTH = 420;
const DEFAULT_CARD_HEIGHT = 588;

export class CollectionCard extends Container {
  public override interactive = true;
  static getTexture(slotId: SlotId, type: CardClass): Texture {
    return Loader.shared.resources[type]?.spritesheet?.textures[slotId]!;
  }
  constructor(
    level: CardLevel,
    type: CardClass,
    showNewLabel = false,
    onClick = () => {},
    size = { width: DEFAULT_CARD_WIDTH, height: DEFAULT_CARD_HEIGHT },
  ) {
    super();
    const slotId = (level + type) as SlotId;

    const card = new Sprite(CollectionCard.getTexture(slotId, type));
    card.width = size.width;
    card.height = size.height;

    this.addChild(card);

    const abilityConfig = cardAbilities[(level + type) as SlotId];
    const unlockedCards = setEnabledCards();

    if (abilityConfig) {
      const ability = new Ability(abilityConfig, this.getRatio());
      this.addChild(ability);
      const abilityMargin = 20 * this.getRatio();
      ability.x = this.width - (ability.width + abilityMargin);
      ability.y = this.height - (ability.height + abilityMargin);
    }

    if (!unlockedCards.includes(slotId)) {
      this.darken();
    }
    if (showNewLabel) {
      this.setLabelNew();
    }

    this.on('pointertap', () => onClick());
  }

  private darken(): void {
    const graphics = new Graphics();
    graphics.beginFill(0x202020, 0.7);
    graphics.drawRect(0, 0, this.width, this.height);
    graphics.endFill();

    this.addChild(graphics);
  }
  private setLabelNew(): void {
    const newIcon = new Sprite(Texture.from(ResourceTypes.newIcon));
    newIcon.x = this.width - newIcon.width;

    this.addChild(newIcon);
  }

  private getRatio(): number {
    return this.width / DEFAULT_CARD_WIDTH;
  }
}
