export enum ResourceTypes {
  buyFeatureConfirmPopupBackplate = 'buyFeatureConfirmPopupBackplate',
  buyFeaturePopupBackplate = 'buyFeaturePopupBackplate',
  buyFeaturePopupRage = 'buyFeaturePopupRage',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureDisable = 'buyFeatureDisable',
  buyFeatureHover = 'buyFeatureHover',
  buyFeaturePressed = 'buyFeaturePressed',
  buyFeature = 'buyFeature',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeatureXBtnDisabled = 'buyFeatureXBtnDisabled',
  buyFeatureXBtnHover = 'buyFeatureXBtnHover',
  buyFeatureXBtnPressed = 'buyFeatureXBtnPressed',
  buyFeatureXBtn = 'buyFeatureXBtn',
  introBg = 'introBg',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  logo = 'logo',
  slotBgBase = 'slotBgBase',
  slotBgGold = 'slotBgGold',
  slotBgIce = 'slotBgIce',
  slotBgNeon = 'slotBgNeon',
  slotBgRainbow = 'slotBgRainbow',
  slotBgRed = 'slotBgRed',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  gameLogo = 'gameLogo',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnCollection = 'infoBtnCollection',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnLibrary = 'infoBtnLibrary',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  newIcon = 'newIcon',
}
