import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, UserBonus, bonusesId } from '../../global.d';
import {
  setAutoSpinsLeft,
  setBetResult,
  setBonusPackAutoPlay,
  setBrokenGame,
  setCardsDisappeared,
  setCardsOpeningInprogress,
  setCurrentBonus,
  setFreePacksBonus,
  setFreePacksCount,
  setFreeSpinsTotalWin,
  setIsAutoSpins,
  setIsSlotBusy,
  setLastRegularWinAmount,
  setMasterPacksBonus,
  setMasterPacksCount,
} from '../../gql/cache';
import { eventManager } from '../../slotMachine/config';
import { getBetResult } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class MasterPacksController extends BaseController {
  public override gameMode: GameMode = GameMode.MASTER_PACKS;

  public static override the = new MasterPacksController();

  protected constructor() {
    super();
  }

  public override enterIdleState(_prevState: States): void {
    setIsSlotBusy(false);
    if (!setMasterPacksCount()) {
      if (setIsAutoSpins() && setBonusPackAutoPlay()) {
        setAutoSpinsLeft(0);
        setIsAutoSpins(false);
        setBonusPackAutoPlay(false);
        if (!setIsSlotBusy() && !setCardsOpeningInprogress()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      }
      setIsSlotBusy(true);
      eventManager.once(EventTypes.HIDE_FINAL_WIN_POPUP, () => {
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.BASE_GAME);
        setIsSlotBusy(false);
        setFreeSpinsTotalWin(0);
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
      });
      setTimeout(() => {
        eventManager.emit(EventTypes.SHOW_FINAL_WIN_POPUP, setFreeSpinsTotalWin(), true);
      }, 200);
    }
  }

  public override enterOpenPackState(_prevState: States): void {
    setMasterPacksCount(setMasterPacksCount() - 1);
    eventManager.emit(EventTypes.UPDATE_WIN_MASTER_PACKS);
    setIsSlotBusy(true);
  }

  public override enterJingleState(_prevState: States): void {
    setCardsDisappeared(true);
    setCardsOpeningInprogress(false);
    eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON_INTENT);
    if (setFreeSpinsTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
    }
    const betResult = getBetResult(setBetResult());
    const bonuses = betResult.bet.data.bonuses;
    const freePacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.FREE_PACKS]);
    const masterPacksBonus = bonuses.find((bonus) => bonus.bonusId === bonusesId[GameMode.MASTER_PACKS]);

    if (masterPacksBonus) {
      setMasterPacksBonus({
        ...(masterPacksBonus as UserBonus),
      });
      setCurrentBonus({
        ...setMasterPacksBonus(),
        isActive: true,
      });
      setMasterPacksCount(setMasterPacksBonus().rounds);
      if (setAutoSpinsLeft() && setBonusPackAutoPlay()) {
        setAutoSpinsLeft(setFreePacksBonus().rounds + setMasterPacksBonus().rounds);
      }
      eventManager.emit(EventTypes.UPDATE_WIN_MASTER_PACKS);
    }
    if (freePacksBonus) {
      setFreePacksBonus({
        ...(freePacksBonus as UserBonus),
      });
      setFreePacksCount(setFreePacksBonus().rounds);
      setCurrentBonus({
        ...setFreePacksBonus(),
        isActive: true,
      });
      if (setAutoSpinsLeft() && setBonusPackAutoPlay()) {
        setAutoSpinsLeft(setFreePacksBonus().rounds + setMasterPacksBonus().rounds);
      }
      eventManager.emit(EventTypes.UPDATE_WIN_FREE_PACKS);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.FREE_PACKS);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterController(_prevGameMode: GameMode): void {
    if (_prevGameMode !== GameMode.FREE_PACKS) {
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
      AudioApi.play({ type: ISongs.BGM_BP_Loop });
    }
    if (setBrokenGame() && setMasterPacksCount()) {
      eventManager.emit(EventTypes.ADD_WIN_MASTER_PACKS);
    }
    Logic.the.changeState(States.IDLE);
    if (setBrokenGame()) AudioApi.play({ type: ISongs.BGM_BP_Loop });
    setIsSlotBusy(false);
    if (setFreeSpinsTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
    }
  }

  public override exitController(_nextGameMode: GameMode): void {
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    if (_nextGameMode === GameMode.BASE_GAME) {
      AudioApi.stop({ type: ISongs.BGM_BP_Loop });
    }
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
  }
}
