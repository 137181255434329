import { Stage } from '@pixi/layers';
import { Application, isMobile, settings } from 'pixi.js';

import { EnterProps, EventTypes, GameMode } from '../global.d';
import {
  setBetResult,
  setBonusPackAutoPlay,
  setFinalWinPopupVisible,
  setIsAutoSpins,
  setIsSlotBusy,
} from '../gql/cache';
import Animator from '../slotMachine/animations/animator';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../slotMachine/config';
import type { Icon } from '../slotMachine/d';
import { updateCoinValueAfterBonuses } from '../utils';

import { IPixiViewParentNode, States } from './config';
import { BaseController } from './controllers/BaseController';
import { BuyFeatureController } from './controllers/BuyFeatureController';
import type { Controller } from './controllers/Controller';
import { FreePacksController } from './controllers/FreePacksController';
import { MasterPacksController } from './controllers/MasterPacksController';
import { AfterWin, BeforeWin, Idle, Init, Intro, Jingle, Transition, WinPresentation } from './states';
import { AppendAbilities } from './states/AppendAbilities';
import { BrokenGame } from './states/BrokenGame';
import { PackOpen } from './states/PackOpen';
import type { State } from './states/State';

settings.FILTER_RESOLUTION = 2;

export class Logic {
  public state: State = Init.the;

  public stopped = false;

  public isReadyForFlip = false;

  public controller: Controller;

  public static the = new Logic();

  public application: Application;

  public animator: Animator;

  public currentSpinResult: Icon[] | null = null;

  private constructor() {
    this.registerStates();
    this.application = new Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      backgroundAlpha: 0,
      width: GAME_CONTAINER_WIDTH,
      height: GAME_CONTAINER_HEIGHT,
    });
    this.initPixiDebugExtension();
    this.application.stage = new Stage();
    this.animator = new Animator(this.application);
    this.controller = BaseController.the;
    this.controller.enterController(null);
  }

  private initPixiDebugExtension(): void {
    (globalThis as unknown as { __PIXI_APP__: unknown }).__PIXI_APP__ = this.application as unknown;
  }

  public init(): void {
    window.addEventListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.FORCE_RESIZE, this.resize.bind(this));
    this.state = Init.the;
    this.state.enterState(States.INIT);
  }

  private registerStates(): void {
    Init.the.nodes.set(States.IDLE, Idle.the);
    Init.the.nodes.set(States.BROKEN_GAME, BrokenGame.the);
    Init.the.nodes.set(States.INTRO, Intro.the);

    Intro.the.nodes.set(States.BROKEN_GAME, BrokenGame.the);
    Intro.the.nodes.set(States.IDLE, Idle.the);

    BrokenGame.the.nodes.set(States.TRANSITION, Transition.the);

    Idle.the.nodes.set(States.PACK_OPEN, PackOpen.the);
    Idle.the.nodes.set(States.TRANSITION, Transition.the);

    PackOpen.the.nodes.set(States.BEFORE_WIN, BeforeWin.the);
    PackOpen.the.nodes.set(States.IDLE, Idle.the);

    BeforeWin.the.nodes.set(States.WIN_PRESENTATION, WinPresentation.the);

    WinPresentation.the.nodes.set(States.APPEND_ABILITIES, AppendAbilities.the);
    WinPresentation.the.nodes.set(States.AFTER_WIN, AfterWin.the);

    AppendAbilities.the.nodes.set(States.AFTER_WIN, AfterWin.the);

    AfterWin.the.nodes.set(States.JINGLE, Jingle.the);

    Jingle.the.nodes.set(States.TRANSITION, Transition.the);
    Jingle.the.nodes.set(States.IDLE, Idle.the);

    Transition.the.nodes.set(States.IDLE, Idle.the);
  }

  public changeState(nextState: States): void {
    this.state.exitState(nextState);
    if (!this.state.nodes.has(nextState)) throw Error(`Invalid change state from ${this.state.name} to ${nextState}`);
    const currentState = this.state.name;
    this.state = this.state.nodes.get(nextState)!;
    eventManager.emit(EventTypes.CHANGE_STATE);
    eventManager.emit(EventTypes.HANDLE_CARD_WIN_ANIMATION);
    this.state.enterState(currentState);
  }

  public spin(): void {
    this.isReadyForFlip = false;
    setBetResult(null);
    this.changeState(States.PACK_OPEN);
  }

  public handleInsufficientFunds(): void {
    if (setIsAutoSpins()) {
      setIsAutoSpins(false);
      setBonusPackAutoPlay(false);
    }
    this.changeState(States.IDLE);
  }

  public flip(): void {
    if (this.isReadyForFlip) {
      setIsSlotBusy(true);
      eventManager.emit(EventTypes.FORCE_FLIP);
    }
  }

  public getCurrentSpinResult(): Icon[] {
    if (this.currentSpinResult === null) throw new Error('NO CURRENT SPIN RESULT');
    return this.currentSpinResult;
  }

  public skipWinAnimation(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
  }

  public changeGameMode(nextGameMode: GameMode, enterProps?: EnterProps): void {
    if (this.state.name !== States.TRANSITION) throw new Error('WRONG STATE FOR CHANGING MODE');
    const currentGameMode = this.controller.gameMode;
    let nextController: Controller;
    switch (nextGameMode) {
      case GameMode.BASE_GAME:
        nextController = BaseController.the;
        break;
      case GameMode.BUY_FEATURE:
        nextController = BuyFeatureController.the;
        break;
      case GameMode.FREE_PACKS:
        nextController = FreePacksController.the;
        break;
      case GameMode.MASTER_PACKS:
        nextController = MasterPacksController.the;
        break;
      default:
        nextController = BaseController.the;
        break;
    }
    if (
      nextController === BuyFeatureController.the ||
      enterProps?.immediate ||
      currentGameMode === BuyFeatureController.the.gameMode
    ) {
      this.controller.exitController(nextGameMode);
      this.controller = nextController;
      eventManager.emit(EventTypes.CHANGE_MODE, { mode: nextGameMode });

      this.controller.enterController(currentGameMode, enterProps);
      return;
    }
    if (nextGameMode === GameMode.BASE_GAME) {
      updateCoinValueAfterBonuses();
    }

    this.controller.exitController(nextGameMode);
    eventManager.emit(EventTypes.CHANGE_MODE, { mode: nextGameMode });

    this.controller = nextController;
    this.controller.enterController(currentGameMode, enterProps);
  }

  private resize(): void {
    const parent = this.application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    const isPortrait = isMobile.any && height > width;
    this.application.renderer.resize(width, height);

    // if (isPortrait) {
    //   this.application.stage.x = width;
    //   this.application.stage.rotation = 1.57079;
    // } else {
    //   this.application.stage.x = 0;
    //   this.application.stage.rotation = 0;
    // }
    //
    // if (isPortrait) {
    //   this.application.renderer.resize(nHeight, nWidth);
    // } else {
    //   this.application.renderer.resize(nWidth, nHeight);
    // }
    eventManager.emit(EventTypes.RESIZE, width, height, isPortrait);
    eventManager.emit(EventTypes.PARENT_MODAL_RESIZE, width, height, isPortrait);
    eventManager.emit(EventTypes.CHILD_MODAL_RESIZE, width, height, isPortrait);
  }

  public isReadyToSpin(): boolean {
    return this.controller.gameMode !== GameMode.BUY_FEATURE && this.state.name === States.IDLE;
  }

  public isReadyToSkip(): boolean {
    return this.state.name === States.AFTER_WIN || setFinalWinPopupVisible();
  }
}
