import { isMobile } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { calcBottomContainerHeight } from '../../utils';
import { ViewContainer } from '../components/ViewContainer';
import { PopupTypes, eventManager } from '../config';

class SafeArea extends ViewContainer {
  constructor() {
    super();
    this.zIndex = 1;
    eventManager.on(EventTypes.OPEN_POPUP, (popupType: PopupTypes) => {
      if (popupType === PopupTypes.BUY_FEATURE || popupType === PopupTypes.BUY_FEATURE_CONFIRMATION) this.zIndex = 10;
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.zIndex = 1;
    });
  }

  private calculateSize(width: number, height: number): number[] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomSpace = bottomContainerHeight;

    newWidth = ((height - bottomSpace) * 4) / 2.75;
    newHeight = height - bottomSpace;
    let acc = 400;
    if (newHeight >= 2200) {
      acc = 1000;
    } else if (newHeight >= 1800) {
      acc = 800;
    } else if (newHeight >= 1300) {
      acc = 700;
    } else if (newHeight >= 1000) {
      acc = 600;
    } else if (newHeight >= 700) {
      acc = 500;
    }
    if (acc <= 500 && isMobile.any && Math.abs(width - height) < 250) {
      acc = 350;
    }
    if (!isMobile.any) {
      acc /= 1.25;
    }
    if (width - acc <= newWidth) {
      newWidth = width - acc;
      newHeight = (width - acc) * 0.65 + bottomSpace;
    }
    return [newWidth, newHeight, bottomSpace];
  }

  protected override resize(width: number, height: number): void {
    const [newWidth, newHeight, bottomSpace] = this.calculateSize(width, height);
    const x = width / 2 - newWidth! / 2;
    const y = (height - bottomSpace!) / 2 - newHeight! / 2;
    this.position.set(x, y);
    eventManager.emit(
      EventTypes.RESIZE_GAME_CONTAINER,
      newWidth,
      newHeight,
      Math.max((width - (newWidth as number)) / 2, 0),
      Math.max((height - (newHeight as number) - (bottomSpace as number)) / 2, 0),
    );
  }
}
export default SafeArea;
