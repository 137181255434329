import type { TextStyleSet } from 'pixi-multistyle-text';
import { TextStyle } from 'pixi.js';

import { CardAbilitiesPattern, CardClass, CardLevel, SlotId } from '../../config';
import type { ICardAbility } from '../d';

export const descriptionPlateTextures = 'descriptionPlateTextures';
export const SMALL_CARD_WIDTH = 120;
export const SMALL_CARD_HEIGHT = 168;
export const levelColors: Record<CardLevel, string> = {
  [CardLevel.Cm]: '#C0B5FB',
  [CardLevel.Un]: '#7ADBFF',
  [CardLevel.Ra]: '#C580FF',
  [CardLevel.My]: '#E90053',
  [CardLevel.Ep]: '#FFC700',
  [CardLevel.Gr]: '#00FFF0',
  [CardLevel.Le]: '#FF6B00',
  [CardLevel.Et]: '#FD99FF',
};

export const levelNames: Record<CardLevel, string> = {
  [CardLevel.Cm]: 'Common',
  [CardLevel.Un]: 'Uncommon',
  [CardLevel.Ra]: 'Rare',
  [CardLevel.My]: 'Mythic',
  [CardLevel.Ep]: 'Epic',
  [CardLevel.Gr]: 'Grand',
  [CardLevel.Le]: 'Legendary',
  [CardLevel.Et]: 'Eternal',
};

export const cardTypeNames: Record<CardClass, string> = {
  [CardClass.LP1]: 'cyberSodaCardName',
  [CardClass.LP2]: 'neonRingCardName',
  [CardClass.LP3]: 'accessKeyCardName',
  [CardClass.MP1]: 'empGrenadeCardName',
  [CardClass.MP2]: 'battleBlasterCardName',
  [CardClass.MP3]: 'masterMaskCardName',
  [CardClass.HP1]: 'hypnoBotCardName',
  [CardClass.HP2]: 'blastBotCardName',
  [CardClass.HP3]: 'modBotCardName',
  [CardClass.HE1]: 'viviaTheMindHacker',
  [CardClass.HE2]: 'daxarThePowerFighter',
  [CardClass.HE3]: 'alinTheTransformancer',
  [CardClass.TCM]: 'linxTheCardmaster',
};

export const abilitiesDescription: Record<CardAbilitiesPattern, string> = {
  [CardAbilitiesPattern.Np]: 'npDescription',
  [CardAbilitiesPattern.Nx]: 'nxDescription',
  [CardAbilitiesPattern.Nup]: 'nupDescription',
  [CardAbilitiesPattern.NM]: 'nmDescription',
  [CardAbilitiesPattern.NxA]: 'nxaDescription',
  [CardAbilitiesPattern.NupA]: 'nupaDescription',
};

interface AbilitiesPreferences {
  prefix: string;
  label: string;
  color: string;
}

export const abilitiesPreferences: Record<CardAbilitiesPattern, AbilitiesPreferences> = {
  [CardAbilitiesPattern.Np]: { prefix: '+', label: '', color: '#5AC4FF' },
  [CardAbilitiesPattern.Nx]: { prefix: 'x', label: '', color: '#FF005C' },
  [CardAbilitiesPattern.Nup]: { prefix: '', label: '', color: '#7DDF00' },
  [CardAbilitiesPattern.NM]: { prefix: '+', label: 'MASTER', color: '#FFF495' },
  [CardAbilitiesPattern.NxA]: { prefix: 'x', label: 'ALL', color: '#FFB0CD' },
  [CardAbilitiesPattern.NupA]: { prefix: '', label: 'ALL', color: '#C3FF76' },
};

export const cardsCost: Record<SlotId, number> = {
  [SlotId.CmLP1]: 0,
  [SlotId.UnLP1]: 3,
  [SlotId.RaLP1]: 6,
  [SlotId.MyLP1]: 20,
  [SlotId.EpLP1]: 30,
  [SlotId.GrLP1]: 40,
  [SlotId.LeLP1]: 60,
  [SlotId.EtLP1]: 90,

  [SlotId.CmLP2]: 0,
  [SlotId.UnLP2]: 3,
  [SlotId.RaLP2]: 6,
  [SlotId.MyLP2]: 20,
  [SlotId.EpLP2]: 30,
  [SlotId.GrLP2]: 40,
  [SlotId.LeLP2]: 60,
  [SlotId.EtLP2]: 90,

  [SlotId.CmLP3]: 0,
  [SlotId.UnLP3]: 3,
  [SlotId.RaLP3]: 6,
  [SlotId.MyLP3]: 20,
  [SlotId.EpLP3]: 30,
  [SlotId.GrLP3]: 40,
  [SlotId.LeLP3]: 60,
  [SlotId.EtLP3]: 90,

  [SlotId.CmMP1]: 0,
  [SlotId.UnMP1]: 4,
  [SlotId.RaMP1]: 10,
  [SlotId.MyMP1]: 30,
  [SlotId.EpMP1]: 60,
  [SlotId.GrMP1]: 90,
  [SlotId.LeMP1]: 120,
  [SlotId.EtMP1]: 180,

  [SlotId.CmMP2]: 0,
  [SlotId.UnMP2]: 4,
  [SlotId.RaMP2]: 10,
  [SlotId.MyMP2]: 30,
  [SlotId.EpMP2]: 60,
  [SlotId.GrMP2]: 90,
  [SlotId.LeMP2]: 120,
  [SlotId.EtMP2]: 180,

  [SlotId.CmMP3]: 0,
  [SlotId.UnMP3]: 4,
  [SlotId.RaMP3]: 10,
  [SlotId.MyMP3]: 30,
  [SlotId.EpMP3]: 60,
  [SlotId.GrMP3]: 90,
  [SlotId.LeMP3]: 120,
  [SlotId.EtMP3]: 180,

  [SlotId.CmHP1]: 0,
  [SlotId.UnHP1]: 12,
  [SlotId.RaHP1]: 20,
  [SlotId.MyHP1]: 60,
  [SlotId.EpHP1]: 150,
  [SlotId.GrHP1]: 180,
  [SlotId.LeHP1]: 240,
  [SlotId.EtHP1]: 360,

  [SlotId.CmHP2]: 0,
  [SlotId.UnHP2]: 4,
  [SlotId.RaHP2]: 8,
  [SlotId.MyHP2]: 20,
  [SlotId.EpHP2]: 50,
  [SlotId.GrHP2]: 60,
  [SlotId.LeHP2]: 80,
  [SlotId.EtHP2]: 120,

  [SlotId.CmHP3]: 0,
  [SlotId.UnHP3]: 12,
  [SlotId.RaHP3]: 20,
  [SlotId.MyHP3]: 60,
  [SlotId.EpHP3]: 150,
  [SlotId.GrHP3]: 180,
  [SlotId.LeHP3]: 240,
  [SlotId.EtHP3]: 360,

  [SlotId.CmHE1]: 0,
  [SlotId.UnHE1]: 30,
  [SlotId.RaHE1]: 60,
  [SlotId.MyHE1]: 120,
  [SlotId.EpHE1]: 210,
  [SlotId.GrHE1]: 300,
  [SlotId.LeHE1]: 450,
  [SlotId.EtHE1]: 600,

  [SlotId.CmHE2]: 0,
  [SlotId.UnHE2]: 10,
  [SlotId.RaHE2]: 20,
  [SlotId.MyHE2]: 40,
  [SlotId.EpHE2]: 70,
  [SlotId.GrHE2]: 100,
  [SlotId.LeHE2]: 150,
  [SlotId.EtHE2]: 200,

  [SlotId.CmHE3]: 0,
  [SlotId.UnHE3]: 30,
  [SlotId.RaHE3]: 60,
  [SlotId.MyHE3]: 120,
  [SlotId.EpHE3]: 210,
  [SlotId.GrHE3]: 300,
  [SlotId.LeHE3]: 450,
  [SlotId.EtHE3]: 600,

  [SlotId.CmTCM]: 0,
  [SlotId.UnTCM]: 80,
  [SlotId.RaTCM]: 140,
  [SlotId.MyTCM]: 200,
  [SlotId.EpTCM]: 300,
  [SlotId.GrTCM]: 400,
  [SlotId.LeTCM]: 600,
  [SlotId.EtTCM]: 1000,
};

export const cardAbilities: Record<string, ICardAbility[]> = {
  [SlotId.UnHP1]: [{ pattern: CardAbilitiesPattern.Np, value: 10 }],
  [SlotId.RaHP1]: [{ pattern: CardAbilitiesPattern.Np, value: 15 }],
  [SlotId.MyHP1]: [{ pattern: CardAbilitiesPattern.Np, value: 20 }],
  [SlotId.EpHP1]: [{ pattern: CardAbilitiesPattern.Np, value: 30 }],
  [SlotId.GrHP1]: [{ pattern: CardAbilitiesPattern.Np, value: 40 }],
  [SlotId.LeHP1]: [{ pattern: CardAbilitiesPattern.Np, value: 50 }],
  [SlotId.EtHP1]: [{ pattern: CardAbilitiesPattern.Np, value: 100 }],

  [SlotId.UnHP2]: [{ pattern: CardAbilitiesPattern.Nx, value: 2 }],
  [SlotId.RaHP2]: [{ pattern: CardAbilitiesPattern.Nx, value: 3 }],
  [SlotId.MyHP2]: [{ pattern: CardAbilitiesPattern.Nx, value: 5 }],
  [SlotId.EpHP2]: [{ pattern: CardAbilitiesPattern.Nx, value: 7 }],
  [SlotId.GrHP2]: [{ pattern: CardAbilitiesPattern.Nx, value: 10 }],
  [SlotId.LeHP2]: [{ pattern: CardAbilitiesPattern.Nx, value: 15 }],
  [SlotId.EtHP2]: [{ pattern: CardAbilitiesPattern.Nx, value: 25 }],

  [SlotId.UnHP3]: [{ pattern: CardAbilitiesPattern.Nup, value: 1 }],
  [SlotId.RaHP3]: [{ pattern: CardAbilitiesPattern.Nup, value: 2 }],
  [SlotId.MyHP3]: [{ pattern: CardAbilitiesPattern.Nup, value: 3 }],
  [SlotId.EpHP3]: [{ pattern: CardAbilitiesPattern.Nup, value: 4 }],
  [SlotId.GrHP3]: [{ pattern: CardAbilitiesPattern.Nup, value: 5 }],
  [SlotId.LeHP3]: [{ pattern: CardAbilitiesPattern.Nup, value: 6 }],
  [SlotId.EtHP3]: [{ pattern: CardAbilitiesPattern.Nup, value: 7 }],

  [SlotId.UnHE1]: [{ pattern: CardAbilitiesPattern.NM, value: 3 }],
  [SlotId.RaHE1]: [{ pattern: CardAbilitiesPattern.NM, value: 5 }],
  [SlotId.MyHE1]: [{ pattern: CardAbilitiesPattern.NM, value: 7 }],
  [SlotId.EpHE1]: [{ pattern: CardAbilitiesPattern.NM, value: 10 }],
  [SlotId.GrHE1]: [{ pattern: CardAbilitiesPattern.NM, value: 15 }],
  [SlotId.LeHE1]: [{ pattern: CardAbilitiesPattern.NM, value: 25 }],
  [SlotId.EtHE1]: [{ pattern: CardAbilitiesPattern.NM, value: 50 }],

  [SlotId.UnHE2]: [{ pattern: CardAbilitiesPattern.NxA, value: 2 }],
  [SlotId.RaHE2]: [{ pattern: CardAbilitiesPattern.NxA, value: 3 }],
  [SlotId.MyHE2]: [{ pattern: CardAbilitiesPattern.NxA, value: 5 }],
  [SlotId.EpHE2]: [{ pattern: CardAbilitiesPattern.NxA, value: 7 }],
  [SlotId.GrHE2]: [{ pattern: CardAbilitiesPattern.NxA, value: 10 }],
  [SlotId.LeHE2]: [{ pattern: CardAbilitiesPattern.NxA, value: 15 }],
  [SlotId.EtHE2]: [{ pattern: CardAbilitiesPattern.NxA, value: 25 }],

  [SlotId.UnHE3]: [{ pattern: CardAbilitiesPattern.NupA, value: 1 }],
  [SlotId.RaHE3]: [{ pattern: CardAbilitiesPattern.NupA, value: 2 }],
  [SlotId.MyHE3]: [{ pattern: CardAbilitiesPattern.NupA, value: 3 }],
  [SlotId.EpHE3]: [{ pattern: CardAbilitiesPattern.NupA, value: 4 }],
  [SlotId.GrHE3]: [{ pattern: CardAbilitiesPattern.NupA, value: 5 }],
  [SlotId.LeHE3]: [{ pattern: CardAbilitiesPattern.NupA, value: 6 }],
  [SlotId.EtHE3]: [{ pattern: CardAbilitiesPattern.NupA, value: 7 }],

  [SlotId.UnTCM]: [
    { pattern: CardAbilitiesPattern.NM, value: 10 },
    { pattern: CardAbilitiesPattern.NupA, value: 1 },
    { pattern: CardAbilitiesPattern.NxA, value: 2 },
  ],
  [SlotId.RaTCM]: [
    { pattern: CardAbilitiesPattern.NM, value: 15 },
    { pattern: CardAbilitiesPattern.NupA, value: 2 },
    { pattern: CardAbilitiesPattern.NxA, value: 3 },
  ],
  [SlotId.MyTCM]: [
    { pattern: CardAbilitiesPattern.NM, value: 20 },
    { pattern: CardAbilitiesPattern.NupA, value: 3 },
    { pattern: CardAbilitiesPattern.NxA, value: 5 },
  ],
  [SlotId.EpTCM]: [
    { pattern: CardAbilitiesPattern.NM, value: 30 },
    { pattern: CardAbilitiesPattern.NupA, value: 4 },
    { pattern: CardAbilitiesPattern.NxA, value: 7 },
  ],
  [SlotId.GrTCM]: [
    { pattern: CardAbilitiesPattern.NM, value: 40 },
    { pattern: CardAbilitiesPattern.NupA, value: 5 },
    { pattern: CardAbilitiesPattern.NxA, value: 10 },
  ],
  [SlotId.LeTCM]: [
    { pattern: CardAbilitiesPattern.NM, value: 50 },
    { pattern: CardAbilitiesPattern.NupA, value: 6 },
    { pattern: CardAbilitiesPattern.NxA, value: 15 },
  ],
  [SlotId.EtTCM]: [
    { pattern: CardAbilitiesPattern.NM, value: 100 },
    { pattern: CardAbilitiesPattern.NupA, value: 7 },
    { pattern: CardAbilitiesPattern.NxA, value: 25 },
  ],
};

export const plateNameTextStyle = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 32,
  fontWeight: 'bold',
  leading: 1,
  lineJoin: 'round',
  whiteSpace: 'normal',
});

export const levelNameTextStyle = {
  fill: '#ffffff',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 24,
  fontWeight: 'bold',
  lineJoin: 'round',
  whiteSpace: 'normal',
  align: 'center',
};

export const plateCostTextStyle = new TextStyle({
  align: 'center',
  fill: '#FCE36E',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 28,
  fontWeight: 'bold',
  leading: 1,
  lineJoin: 'round',
  whiteSpace: 'normal',
});

export function getAbilityDescriptionTextStyles(pattern: CardAbilitiesPattern): TextStyleSet {
  return {
    default: {
      align: 'center',
      fill: 'white',
      fontSize: 21,
      whiteSpace: 'normal',
      fontWeight: '700',
    },
    span: { fill: abilitiesPreferences[pattern].color },
  };
}

export function getCardDescriptionTextStyles(level: CardLevel): TextStyleSet {
  return {
    default: {
      fill: 'white',
      fontSize: 32,
      whiteSpace: 'normal',
      fontWeight: '700',
    },
    rarity: { fill: levelColors[level] },
    total: { fill: '#777', fontSize: 33 },
  };
}
