import { Container, Loader, Sprite, Texture } from 'pixi.js';

import type { CardLevel } from '../../config';
import { EventTypes } from '../../global.d';
import { setSelectedRewardCard, setSelectedRewardTable, setUnlockedBackgrounds } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { ModalService } from '../ModalService';
import { eventManager } from '../config';
import { BonusRewardsBtn } from '../controlButtons/bonusRewardsBtn';

import { RewardsEntities, rewards } from './bonusRewards.model';
import { RewardInfo } from './rewardInfo';

export class BonusRewards extends Container {
  public override name = 'BonusRewards';
  public override interactive = true;
  private cards = Loader.shared.resources[RewardsEntities.cards]?.spritesheet?.textures;
  private tables = Loader.shared.resources[RewardsEntities.tables]?.spritesheet?.textures;
  private radioButtons = Loader.shared.resources[RewardsEntities.buttons]?.spritesheet?.textures;
  private tablesRadioButtonList: Sprite[] = [];
  private cardsRadioButtonList: Sprite[] = [];
  private screenWidth = 0;
  private screenHeight = 0;

  constructor(private newRewards: CardLevel[] = []) {
    super();
    this.zIndex = 2;

    this.addChild(this.getBonusRewardsBtn(), this.getRewards());
  }

  private getBonusRewardsBtn(): Container {
    const bonusRewardsBtn = new BonusRewardsBtn(() => ModalService.the.hide());

    // Workaround need to render controlButton, problem with realization

    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);

    return bonusRewardsBtn;
  }

  private getRewards(): Container {
    const cardsContainer = new Container();
    cardsContainer.name = 'Cards';
    const tablesContainer = this.getTablesContainer();
    const rewardsContainer = this.getRewardsContainer();

    rewards.forEach((reward, index) => {
      const card = this.getReward(reward.card, 'cards', index + 1);
      const table = this.getReward(reward.table, 'tables', index + 1);

      cardsContainer.addChild(card);
      tablesContainer.addChild(table);
    });

    rewardsContainer.addChild(cardsContainer, tablesContainer);
    return rewardsContainer;
  }

  private getRewardsContainer(): Container {
    const rewardsContainer = new Container();
    rewardsContainer.name = 'Rewards';

    eventManager.on(EventTypes.PARENT_MODAL_RESIZE, (width: number, height: number) => {
      this.screenWidth = width;
      this.screenHeight = height;
      this.setRewardsContainerSizes(rewardsContainer, width, height);
    });

    return rewardsContainer;
  }

  private setRewardsContainerSizes(rewardsContainer: Container, width: number, height: number): void {
    if (this.children.length) {
      const yScale = height / 1080;
      const xScale = width / 1500;
      rewardsContainer.scale.set(Math.min(yScale, xScale, 1));
      rewardsContainer.x = (width - rewardsContainer.width) / 2;
      rewardsContainer.y = (height - rewardsContainer.height) / 2;
    } else {
      eventManager.removeListener(EventTypes.PARENT_MODAL_RESIZE);
    }
  }

  private getTablesContainer(): Container {
    const tablesContainer = new Container();
    tablesContainer.name = 'Tables';
    tablesContainer.y = 382;

    return tablesContainer;
  }

  private getReward(rewardName: CardLevel, type: 'cards' | 'tables', countNumber: number): Container {
    const container = new Container();
    const unlockedBackgrounds = setUnlockedBackgrounds();
    const isOpened = unlockedBackgrounds.includes(rewardName);
    const reward: Sprite =
      type === 'cards'
        ? new Sprite(this.cards![isOpened ? `${rewardName}-opened` : `${rewardName}-closed`])
        : new Sprite(this.tables![isOpened ? `${rewardName}-opened` : `${rewardName}-closed`]);

    container.name = `${rewardName}_${type}_${isOpened ? 'opened' : 'closed'}`;
    reward.interactive = true;
    reward.name = rewardName;
    container.addChild(reward);
    const rewardRadio = this.getRadioButton(reward);
    container.addChild(rewardRadio);
    this.setRewardSize(container, countNumber);
    if (this.newRewards.includes(rewardName)) {
      this.setLabelNew(reward);
    }
    reward.on('pointertap', () => {
      this.cardClick(rewardRadio, type!, rewardName!);
    });

    return container;
  }

  private setRewardSize(reward: Container, countNumber: number): void {
    reward.width = 210;
    const marginRewards = 32;
    reward.x = (countNumber - 1) * (reward.width + marginRewards);
  }

  private cardClick = (radioButton: Sprite, typeName: string, rewardName: string) => {
    const unlockedBackgrounds = setUnlockedBackgrounds();
    const isOpened = unlockedBackgrounds.includes(rewardName as CardLevel);
    if (isOpened) {
      if (typeName === 'tables') {
        setSelectedRewardTable(rewardName as CardLevel);
        this.tablesRadioButtonList.forEach((radio) => {
          const disabled = radio.name.includes('disabled');
          if (radio.name !== radioButton.name && !disabled) {
            radio.texture = this.radioButtons!['radio'] as Texture;
            radio.name = `${rewardName}_${typeName}_unchecked`;
          }
        });
        radioButton.texture = this.radioButtons!['radio_checked'] as Texture;
        eventManager.emit(EventTypes.UPDATE_BACKGROUND);
      } else {
        setSelectedRewardCard(rewardName as CardLevel);
        this.cardsRadioButtonList.forEach((radio) => {
          const disabled = radio.name.includes('disabled');
          if (radio.name !== radioButton.name && !disabled) {
            radio.texture = this.radioButtons!['radio'] as Texture;
            radio.name = `${rewardName}_${typeName}_unchecked`;
          }
        });
        radioButton.texture = this.radioButtons!['radio_checked'] as Texture;
        eventManager.emit(EventTypes.UPDATE_CARD_BACKGROUND);
      }
      radioButton.name = `${rewardName}_${typeName}_checked`;
    } else {
      const rewardInfo = new RewardInfo();
      ModalService.the.open(rewardInfo, { title: 'appearance' });

      rewardInfo.viewReward(rewardName as CardLevel, this.screenWidth, this.screenHeight);
    }
  };

  private getRadioButton(entity: Sprite): Sprite {
    const [rewardName, typeName, opened] = entity.parent.name.split('_');
    const exists = opened === 'opened';

    let radioButton: Sprite;
    if (exists) {
      if (typeName === 'tables') {
        const selectedRewardTable = setSelectedRewardTable();
        const isSelected = selectedRewardTable === entity.name;
        radioButton = new Sprite(this.radioButtons![isSelected ? 'radio_checked' : 'radio']);
        radioButton.name = `${rewardName}_${typeName}_${isSelected ? 'checked' : 'unchecked'}`;
      } else {
        const selectedRewardCard = setSelectedRewardCard();
        const isSelected = selectedRewardCard === entity.name;
        radioButton = new Sprite(this.radioButtons![isSelected ? 'radio_checked' : 'radio']);
        radioButton.name = `${rewardName}_${typeName}_${isSelected ? 'checked' : 'unchecked'}`;
      }
    } else {
      radioButton = new Sprite(this.radioButtons!['radio_disabled']);
      radioButton.name = `${rewardName}_${typeName}_disabled`;
    }
    const marginBetweenCards = 32;
    radioButton.interactive = true;
    radioButton.anchor.set(0.5);
    radioButton.x = entity.width / 2;
    radioButton.y = entity.height + marginBetweenCards;
    radioButton.width = marginBetweenCards;
    radioButton.height = marginBetweenCards;
    if (typeName === 'tables') {
      this.tablesRadioButtonList.push(radioButton);
    } else {
      this.cardsRadioButtonList.push(radioButton);
    }
    radioButton.on('click', () => {
      this.cardClick(radioButton, typeName!, rewardName!);
    });
    radioButton.on('touchstart', () => {
      this.cardClick(radioButton, typeName!, rewardName!);
    });
    return radioButton;
  }

  private setLabelNew(reward: Container): void {
    const newIcon = new Sprite(Texture.from(ResourceTypes.newIcon));
    const offset = 10;
    newIcon.position.set(reward.width - newIcon.width + offset, -offset);

    reward.addChild(newIcon);
  }
}
