export const gameRules1 = [
  { key: 'infoGameRules1_1', buyFeatureOnly: false },
  { key: 'infoGameRules1_2', buyFeatureOnly: false },
  { key: 'infoGameRules1_3', buyFeatureOnly: false },
];
export const gameRules2 = [
  { key: 'infoGameRules1_4', buyFeatureOnly: false },
  { key: 'infoGameRules1_5', buyFeatureOnly: false },
  { key: 'infoGameRules1_6', buyFeatureOnly: false },
  { key: 'infoGameRules1_7', buyFeatureOnly: false },
  { key: 'infoGameRules1_8', buyFeatureOnly: false },
  { key: 'infoGameRules1_9', buyFeatureOnly: false },
  { key: 'infoGameRules1_10', buyFeatureOnly: false },
  { key: 'infoGameRules1_11', buyFeatureOnly: false },
];

export const infoBaseFeatureDescs = ['infoBaseDescs_1', 'infoBaseDescs_2'];

export const infoBonusFeatureDescs = [
  'infoBonusFeatureDescs_1',
  'infoBonusFeatureDescs_2',
  'infoBonusFeatureDescs_3',
  'infoBonusFeatureDescs_4',
  'infoBonusFeatureDescs_5',
  'infoBonusFeatureDescs_6',
];

export const infoCardRarityFeatureDescs = [
  'infoCardRarityFeatureDescs_1',
  'infoCardRarityFeatureDescs_2',
  'infoCardRarityFeatureDescs_3',
  'infoCardRarityFeatureDescs_4',
  'infoCardRarityFeatureDescs_5',
  'infoCardRarityFeatureDescs_6',
];

export const infoCardOrderFeatureDescs = [
  'infoCardOrderFeatureDescs_1',
  'infoCardOrderFeatureDescs_2',
  'infoCardOrderFeatureDescs_3',
  'infoCardOrderFeatureDescs_4',
  'infoCardOrderFeatureDescs_5',
  'infoCardOrderFeatureDescs_6',
  'infoCardOrderFeatureDescs_7',
  'infoCardOrderFeatureDescs_8',
];

export const infoCardRewardFeatureDescs = [
  'infoCardRewardFeatureDescs_1',
  'infoCardRewardFeatureDescs_2',
  'infoCardRewardFeatureDescs_3',
  'infoCardRewardFeatureDescs_4',
  'infoCardRewardFeatureDescs_5',
  'infoCardRewardFeatureDescs_6',
];

export const infoCardAbilityResolveOrderFeatureDescs = [
  'infoResolveOrderFeatureDescs_1',
  'infoResolveOrderFeatureDescs_2',
  'infoResolveOrderFeatureDescs_3',
];

export const infoFeatureDesc = ['infoFeatureDesc_1', 'infoFeatureDesc_2'];
