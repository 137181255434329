import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';
import type * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import type { Play } from '@phoenix7dev/audio-api/dist/d';

import { CardLevel, ISongs, SlotId } from '../config';
import { GameMode, ISettledBet } from '../global.d';
import { setBetAmount, setIsDuringBigWinLoop, setIsSuspended, setSlotConfig } from '../gql/cache';
import type { IConfig } from '../gql/d';
import { States } from '../logic/config';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MEGA_WIN_AMOUNT_LIMIT,
  WinStages,
} from '../slotMachine/config';

import { remoteStorage } from './remoteStorage';
import { normalizeCoins } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const getBetResult = (betResult: ISettledBet | null): ISettledBet => {
  if (betResult === null) throw new Error('Invalid bet result');
  return betResult;
};

export const getUnlockedRewards = (name: string, value: CardLevel[]): CardLevel[] => {
  if (!remoteStorage.isReady) {
    return value;
  }
  const item = remoteStorage.getItem<IConfig>('rewards');
  if (!item) {
    return value;
  }
  return item[name as string] as CardLevel[];
};

export const setUnlockedRewards = (name: string, value: CardLevel[]): CardLevel[] => {
  if (!remoteStorage.isReady) {
    return value;
  }
  const item = remoteStorage.getItem<IConfig>('rewards');
  let newValue = [...value];
  if (item) {
    newValue = [...new Set([...(item[name as string] as []), ...value])];
    if (JSON.stringify(item[name as string]) === JSON.stringify(newValue)) return newValue;
    Object.assign(item, { [name]: newValue });
    remoteStorage.setItem('rewards', item);
  }
  if (!item) {
    remoteStorage.setItem('rewards', { [name]: newValue });
  }
  return newValue;
};

export const getUnlockedCards = (name: string, value: SlotId[]): SlotId[] => {
  if (!remoteStorage.isReady) {
    return value;
  }
  const item = remoteStorage.getItem<IConfig>('unlockedCards');
  if (!item) {
    return value;
  }
  return item[name as string] as SlotId[];
};

export const setUnlockedCards = (name: string, value: SlotId[]): SlotId[] => {
  if (!remoteStorage.isReady) {
    return value;
  }
  const item = remoteStorage.getItem<IConfig>('unlockedCards');
  let newValue = [...value];
  if (item) {
    newValue = [...new Set([...(item[name as string] as []), ...value])];
    if (JSON.stringify(item[name as string]) === JSON.stringify(newValue)) return newValue;
    Object.assign(item, { [name]: newValue });
    remoteStorage.setItem('unlockedCards', item);
  }
  if (!item) {
    remoteStorage.setItem('unlockedCards', { [name]: newValue });
  }
  return newValue;
};

export const getBackground = (name: string, value: CardLevel): CardLevel => {
  if (!remoteStorage.isReady) {
    return value;
  }
  const item = remoteStorage.getItem<IConfig>('backgrounds');
  if (!item) {
    return value;
  }
  return item[name as string] as CardLevel;
};

export const setBackground = (name: string, value: CardLevel): CardLevel => {
  if (!remoteStorage.isReady) {
    return value;
  }
  const item = remoteStorage.getItem<IConfig>('backgrounds');
  if (item) {
    if (item[name as string] === value) return value;
    Object.assign(item, { [name]: value });
    remoteStorage.setItem('backgrounds', item);
  }
  if (!item) {
    remoteStorage.setItem('backgrounds', { [name]: value });
  }
  return value;
};

export const getWinStage = (winAmount: number): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const multiplier = normalizeCoins(winAmount) / betAmount;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME;
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    if (bet.coinAmount) {
      return (bet.coinAmount * bet.totalAmount * (bet.coinValue || 100)) / 100;
    }
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || setSlotConfig().lines.length)) / 100;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  slotState,
  isSlotBusy,
  isPopupOpened,
}: {
  gameMode: GameMode;
  slotState: States;
  isSlotBusy: boolean;
  isPopupOpened: boolean;
}): boolean => {
  if (isPopupOpened) {
    return false;
  }

  if (isSlotBusy) {
    return false;
  }

  if (slotState !== States.IDLE && slotState !== States.BEFORE_WIN) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (options: Partial<DropShadowFilterOptions>): PIXI.Filter => {
  return new DropShadowFilter(options) as PIXI.Filter;
};

export const getBGMSoundByGameMode = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.BGM_BG_Melo_Loop;
    case GameMode.FREE_PACKS:
    case GameMode.MASTER_PACKS:
      return ISongs.BGM_BP_Loop;
    default:
      return ISongs.BGM_BG_Melo_Loop;
  }
};

export const handleChangeRestriction = (mode: GameMode): void => {
  setIsSuspended(false);
  AudioApi.unSuspend();
  AudioApi.changeRestriction(false, []);
  const listToPlay: Play[] = [];
  if (setIsDuringBigWinLoop()) {
    listToPlay.push({ type: ISongs.BigWin_Loop });
  }
  switch (mode) {
    case GameMode.BASE_GAME:
      listToPlay.push({ type: ISongs.BGM_BG_Melo_Loop });
      break;
    case GameMode.FREE_PACKS:
    case GameMode.MASTER_PACKS:
      listToPlay.push({ type: ISongs.BGM_BP_Loop });
      break;
    default:
      listToPlay.push({ type: ISongs.BGM_BG_Melo_Loop });
      break;
  }
  AudioApi.playlist({ list: listToPlay });
};

export const getLayerOrderByName = (name: string): number => {
  switch (name) {
    case 'Background':
      return 8;
    case 'BonusRewards':
      return 1;
    case 'SafeArea':
      return 9;
    case 'Backdrop':
      return 10;
    case 'BigWinContainer':
      return 11;
    default:
      return 0;
  }
};
