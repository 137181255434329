import { Container, Loader, Sprite, isMobile } from 'pixi.js';

import { EventTypes } from '../../global.d';
import i18n from '../../i18next';
import { calcPercentage } from '../../utils';
import { titleBaseTextStyle } from '../buyFeature/textStyles';
import { TextField } from '../components/TextField';
import { eventManager } from '../config';

import { RewardsEntities } from './bonusRewards.model';

export class Title extends Container {
  public override name = 'Title';
  public override visible = true;
  private titleBg = Loader.shared.resources[RewardsEntities.title]?.texture;
  constructor(message = 'Title') {
    super();
    const title = new Sprite(this.titleBg);
    title.anchor.set(0.5, 0.5);
    title.addChild(this.getText(message));
    title.width = isMobile.any ? 128 : 248;
    title.height = isMobile.any ? 38 : 76;
    if (!isMobile.any) {
      title.scale.y = 0.8;
    }
    this.addChild(title);
    this.setTitleSize(title, window.innerWidth, window.innerHeight);

    eventManager.on(EventTypes.RESIZE, (width, height) => this.setTitleSize(title, width, height));
  }
  private getText(message: string): Container {
    const textField = new TextField(i18n.t<string>(message), 260, 50, {
      ...titleBaseTextStyle,
      fontSize: 40,
      strokeThickness: 9,
      fontFamily: 'NotoSans-SemiCondensedBold',
      lineHeight: 40,
    });
    textField.text.anchor.set(0.5, 0.5);
    textField.text.position.set(this.width / 2, this.height / 2 - 5);

    return textField.getText();
  }

  private setTitleSize(title: Container, appWidth: number, appHeight: number): void {
    if (this.children.length) {
      title.x = calcPercentage(appWidth, 50);
      title.y = calcPercentage(appHeight, 10);
    }
  }
}
