import { formatNumber } from '@phoenix7dev/utils-fe';

import type { SlotId } from '../../config';
import { setCoinAmount, setCurrency } from '../../gql/cache';
import { countCoins, normalizeCoins } from '../../utils';

import { cardsCost } from './collection.model';

export function getSlotPrice(slotId: SlotId): string {
  const currency = setCurrency();
  return formatNumber({
    currency: currency,
    value: normalizeCoins(countCoins({ totalAmount: cardsCost[slotId], coinAmount: setCoinAmount() })),
    showCurrency: false,
  });
}
