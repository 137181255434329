import { calcPercentage } from '../../utils';
import { ControlButton } from '../controlButtons';

class CloseBtn extends ControlButton {
  constructor(onClick: () => void = () => {}) {
    super('close');
    this.interactive = !this.isDisabled;
    this.on('click', () => onClick());
    this.on('touchstart', () => onClick());
  }

  public override handlePosition(): void {
    if (this.children.length) {
      const x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 10)
        : calcPercentage(this.applicationSize.width, 95);
      const y = calcPercentage(this.applicationSize.height, 10);
      this.setSize(calcPercentage(this.applicationSize.width, 4));

      this.x = x;
      this.y = y;
    } else {
      this.removeAllListeners();
    }
  }
}

export default CloseBtn;
