import i18n from 'i18next';
import * as _ from 'lodash';
import { ITextStyle, Sprite, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setBetAmount, setBonuses, setCoinAmount, setCurrency, setSlotConfig } from '../../../gql/cache';
import type { IBetSettings } from '../../../gql/d';
import { getBetsSetting } from '../../../gql/fromFragment';
import { ResourceTypes } from '../../../resources.d';
import { normalizeCoins, showCurrency } from '../../../utils';
import { betValueStyle, titleBaseTextStyle, totalCostBaseTextStyle } from '../../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { PopupTypes, SLOTS_CONTAINER_WIDTH, eventManager } from '../../config';
import type { LineSet } from '../../d';
import { PopupController } from '../PopupController';
import { Popup } from '../popup';

import BuyFeaturePopupBackgroundStatic from './buyFeaturePopupBackgroundStatic';

class BuyFeaturePopup extends Popup {
  private popupBg!: BuyFeaturePopupBackgroundStatic;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: TextField;

  private amountText: TextField;

  private betTitleText: TextField;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private betAmountBackplate: Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private coinMultiplier: number;

  private currency = 'FUN';

  private betValue: TextField;

  private totalCostValue: TextField;

  private isNoFunds: boolean;

  private balance: number;

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.coinMultiplier = (setSlotConfig().lineSets[0] as LineSet).coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.initBackground();
    this.titleText = this.initTitle();
    this.amountText = this.initAmountText();
    this.betTitleText = this.initBetTitleText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.betValue = this.initBetValue();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.init();
    this.x = SLOTS_CONTAINER_WIDTH / 2 - this.popupBg.width / 2;
    this.y = 300;
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      PopupController.the.closeCurrentPopup();
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
  }

  public override show(): void {
    super.show();
    this.visible = true;
  }

  public close(): void {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_POPUP);
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.betAmountBackplate);
    this.addChild(this.titleText.getText());
    this.addChild(this.amountText.getText());
    this.addChild(this.betTitleText.getText());
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.betValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    this.addChild(this.totalCostValue.getText());
  }

  private initBackground(): void {
    this.popupBg = new BuyFeaturePopupBackgroundStatic();
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureTitleBase'),
      209,
      52,
      titleBaseTextStyle as Partial<ITextStyle>,
    );
    title.text.y = 50;
    title.text.x = title.width / 2 + (this.popupBg.width - title.width) / 2;
    title.text.anchor.set(0.5, 0.5);

    return title;
  }

  private initAmountText(): TextField {
    const amountText = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      400,
      100,
      totalCostBaseTextStyle as Partial<ITextStyle>,
    );
    amountText.text.y = 200;
    amountText.text.x = amountText.text.x = amountText.width / 2 + (this.popupBg.width - amountText.width) / 2;
    amountText.text.anchor.set(0.5, 0);

    return amountText;
  }

  private initBetTitleText(): TextField {
    const betTitleText = new TextField(i18n.t<string>('bet'), 400, 100, totalCostBaseTextStyle as Partial<ITextStyle>);
    betTitleText.text.y = 75;
    betTitleText.text.x = betTitleText.text.x = betTitleText.width / 2 + (this.popupBg.width - betTitleText.width) / 2;
    betTitleText.text.anchor.set(0.5, 0);

    return betTitleText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleMinus.bind(this),
      onTouchStart: this.handleMinus.bind(this),
    });
    minusBtn.y = 160;
    minusBtn.x = 115;
    minusBtn.height = 69;
    minusBtn.width = 59;
    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handlePlus.bind(this),
      onTouchStart: this.handlePlus.bind(this),
    });
    plusBtn.y = 160;
    plusBtn.x = 390;
    plusBtn.height = 69;
    plusBtn.width = 59;

    return plusBtn;
  }

  private initBetAmountBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    input.width = 184;
    input.height = 56;
    input.y = 155;
    input.x = input.x = input.width / 2 + (this.popupBg.width - input.width) / 2;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initBetValue(): TextField {
    const betValue = new TextField(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      300,
      100,
      betValueStyle as Partial<ITextStyle>,
    );
    betValue.text.y = 140;
    betValue.text.x = betValue.text.x = betValue.width / 2 + (this.popupBg.width - betValue.width) / 2;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initTotalCostValue(): TextField {
    const totalCostValue = new TextField(this.getTotalCost(), 300, 100, titleBaseTextStyle as Partial<ITextStyle>);
    totalCostValue.text.y = 240;
    totalCostValue.text.x = totalCostValue.text.x =
      totalCostValue.width / 2 + (this.popupBg.width - totalCostValue.width) / 2;
    totalCostValue.text.anchor.set(0.5, 0);

    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnDisabled),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.width = 100;
    cancelBtn.height = 60;
    cancelBtn.y = 335;
    cancelBtn.x = isMobile.any ? 180 : 169;
    return cancelBtn;
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    PopupController.the.closeCurrentPopup();
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleClickOk.bind(this),
      onTouchStart: this.handleClickOk.bind(this),
      // textFiled: this.initOkButtonText(),
    });
    okBtn.y = 335;
    okBtn.x = 335;
    okBtn.width = 100;
    okBtn.height = 60;
    return okBtn;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    const isMinBet = this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet;
    if (isMinBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    const isMaxBet = this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet;
    if (isMaxBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private updateBets(): void {
    this.betValue.text.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
    this.totalCostValue.text.text = this.getTotalCost();
  }

  private getTotalCost = (): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (): number => {
    const bonuses = setBonuses();
    return bonuses[0] ? bonuses[0].coinAmount : 1;
  };

  private handleClickOk = (): void => {
    AudioApi.play({ type: ISongs.SFX_UI_General });
    if (!this.isNoFunds) {
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, {
        totalCost: this.getTotalCost(),
        coinAmount: this.betSettings.bets[this.betAmount] as number,
      });
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    const isMinBet = bet === this.betSettings!.minBet;
    const isMaxBet = bet === this.betSettings!.maxBet;

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount());
    if (this.isNoFunds) {
      this.okBtn.disable();
    } else {
      this.okBtn.enable();
    }
    if (isMinBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (isMaxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }
  };
}

export default BuyFeaturePopup;
