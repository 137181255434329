import type React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './restrictRotateBanner.module.scss';

const RestrictRotateBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['banner']}>
      <div className={styles['banner-description']}>
        <img alt="banner icon" src="images/rotate-icon.png" />

        <div>{t('pleaseRotateYourDevice')}</div>
      </div>
    </div>
  );
};

export default RestrictRotateBanner;
