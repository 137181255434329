import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import {
  infoBaseFeatureDescs,
  infoBonusFeatureDescs,
  infoCardAbilityResolveOrderFeatureDescs,
  infoCardOrderFeatureDescs,
  infoCardRarityFeatureDescs,
  infoCardRewardFeatureDescs,
  infoFeatureDesc,
} from '../../config/gameRules';
import { setSlotConfig } from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const CollectFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoBaseTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoBaseFeatureDescs.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const FreeSpinsFeature: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoCardAbilityTitle')}</h1>
      <ol>
        <li
          className={`${styles['p']} ${styles['left']}`}
          dangerouslySetInnerHTML={{ __html: t('infoCardAbilitySubTitle_1') }}
        ></li>
        <div className={`${styles['p']} ${styles['left']}`}>
          {infoBonusFeatureDescs.map((keyName) => (
            <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
        <li
          className={`${styles['p']} ${styles['left']}`}
          dangerouslySetInnerHTML={{ __html: t('infoCardAbilitySubTitle_2') }}
        ></li>
        <div className={`${styles['p']} ${styles['left']}`}>
          {infoCardRarityFeatureDescs.map((keyName) => (
            <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
        <div className={`${styles['p']} ${styles['left']}`}>
          {t('infoCardOrderTitle')}
          <div className={`${styles['p']} ${styles['left']}`}>
            {infoCardOrderFeatureDescs.map((keyName) => (
              <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
            ))}
          </div>
        </div>
        <li
          className={`${styles['p']} ${styles['left']}`}
          dangerouslySetInnerHTML={{ __html: t('infoCardAbilitySubTitle_3') }}
        ></li>
        <div className={`${styles['p']} ${styles['left']}`}>
          {infoCardRewardFeatureDescs.map((keyName) => (
            <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </ol>
      <h1 className={styles['title']}>{t('infoCardAbilityResolveOrderTitle')}</h1>
      <ol>
        <div className={`${styles['p']} ${styles['left']}`}>
          {infoCardAbilityResolveOrderFeatureDescs.map((keyName) => (
            <li key={keyName}>
              <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
            </li>
          ))}
        </div>
      </ol>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoFeatureBuyTitle')}</h1>
      <div className={`${styles['buyfeaturecontainer']}`}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.buyFeature)} alt="buyFeatureBtn" />
        <p dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn') }} />
      </div>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoFeatureDesc.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles['gameRules']}>
      <CollectFeature />
      <FreeSpinsFeature />
      {isBuyFeatureEnabled ? <BuyFeatureFunction /> : null}
    </div>
  );
};

export default Features;
