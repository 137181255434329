import { ITextStyle, TextStyle } from 'pixi.js';

export const cardPriceTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFF495',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 28,
  fontWeight: 'bold',
  leading: 1,
  lineHeight: 38,
  lineJoin: 'round',
  strokeThickness: 7,
  whiteSpace: 'normal',
});

export const cardAbilitiesValueTextStyle = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 27,
  fontWeight: 'bold',
  leading: 1,
  lineHeight: 37,
  lineJoin: 'round',
  strokeThickness: 7,
  whiteSpace: 'normal',
});

export const cardAbilitiesLabelTextStyle = new TextStyle({
  align: 'center',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 18,
  fontWeight: 'bold',
  leading: 1,
  lineHeight: 25,
  lineJoin: 'round',
  strokeThickness: 7,
  whiteSpace: 'normal',
});

export const cardAbilitiesLabelStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: '#FFF495',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 18,
  fontWeight: 'bold',
  leading: 1,
  lineHeight: 25,
  lineJoin: 'round',
  strokeThickness: 7,
  whiteSpace: 'normal',
};
