import { CardLevel } from '../../config';

interface Rewards {
  card: CardLevel;
  table: CardLevel;
}
export const rewards: Rewards[] = [
  { card: CardLevel.Cm, table: CardLevel.Cm },
  { card: CardLevel.Ra, table: CardLevel.My },
  { card: CardLevel.Ep, table: CardLevel.Ep },
  { card: CardLevel.Gr, table: CardLevel.Gr },
  { card: CardLevel.Le, table: CardLevel.Le },
  { card: CardLevel.Et, table: CardLevel.Et },
];

export enum RewardsEntities {
  cards = 'cards',
  tables = 'tables',
  buttons = 'buttons',
  title = 'title',
}
