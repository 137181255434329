import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { CardAbilitiesPattern, ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setIsTurboSpin } from '../../../gql/cache';
import type { IEntryAnimation } from '../../animations/d';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';
import type { RewardsPopupProps } from '../../d';

import { RewardsActions } from './rewardsActions';
import type { RewardsPopup } from './rewardsPopup';

class RarityPopupBackgroundAnimated extends ViewContainer {
  public spine: Spine;

  private popup: RewardsPopup;

  private popupProps!: RewardsPopupProps;

  constructor(popup: RewardsPopup) {
    super();
    this.popup = popup;
    this.spine = new Spine(Loader.shared.resources['popups']!.spineData!);
    this.addChild(this.spine);
    this.animationAndEventsListener();
  }

  private animationAndEventsListener(): void {
    this.spine.state.addListener({
      complete: (entry: IEntryAnimation) => {
        if (entry.animation.name.startsWith('particles_out_')) {
          this.popup.visible = false;
          this.spine.state.addEmptyAnimation(0, 0, 0);
          (this.spine as unknown as { lastTime: number | null }).lastTime = null;
        }
      },
      start: (entry: IEntryAnimation) => {
        if (entry.animation.name.includes('_loop')) {
          this.spine.state.timeScale = 1;
        }
      },
      event: (_entry, event) => {
        switch (event.data.name) {
          case 'Np_in':
            AudioApi.stop({ type: ISongs.FP_EntrancePopup });
            AudioApi.play({ type: ISongs.FP_EntrancePopup });
            break;
          case 'NM_in':
            AudioApi.stop({ type: ISongs.MP_EntrancePopup });
            AudioApi.play({ type: ISongs.MP_EntrancePopup });
            break;
          case 'Nx_NxA_in':
            if (this.popup.rewardsProps.pattern === CardAbilitiesPattern.Nx) {
              AudioApi.stop({ type: ISongs.Card_Multiply_Popup });
              AudioApi.play({ type: ISongs.Card_Multiply_Popup });
            } else {
              AudioApi.stop({ type: ISongs.All_Multiply_Popup });
              AudioApi.play({ type: ISongs.All_Multiply_Popup });
            }
            break;
          case 'Nup_NUpA_in':
            if (this.popup.rewardsProps.pattern === CardAbilitiesPattern.Nup) {
              AudioApi.stop({ type: ISongs.Card_Upgrade_Popup });
              AudioApi.play({ type: ISongs.Card_Upgrade_Popup });
            } else {
              AudioApi.stop({ type: ISongs.All_Upgrade_Popup });
              AudioApi.play({ type: ISongs.All_Upgrade_Popup });
            }
            break;
          case 'particles_out_Nx_pop_up':
          case 'particles_out_Nup_pop_up':
          case 'particles_out_NxA_pop_up':
          case 'particles_out_NupA_pop_up':
          case 'particles_out_NM_pop_up':
          case 'particles_out_Np_pop_up':
            AudioApi.play({ type: ISongs.Particle_start });
            break;
          case 'saund_particles':
            AudioApi.play({ type: ISongs.Particle_Land });
            (this.spine as unknown as { lastTime: number | null }).lastTime = null;
            break;
          case 'change_multiplier':
          case 'add_free_pack':
          case 'add_master_pack':
          case 'change_rarity_card_1':
          case 'change_rarity_card_2':
          case 'change_rarity_card_3':
          case 'change_rarity_card_4':
          case 'change_rarity_card_5':
            if (event.data.name === 'add_free_pack') {
              eventManager.emit(EventTypes.WIN_FREE_PACKS, true);
            } else if (event.data.name === 'add_master_pack') {
              eventManager.emit(EventTypes.WIN_MASTER_PACKS, true);
            }
            RewardsActions.the.emitEvent(this.popupProps.inId, this.popupProps.pattern);
            AudioApi.play({ type: ISongs.Particle_final });
            break;
          default:
        }
      },
    });
  }

  public show(props: RewardsPopupProps): void {
    this.popupProps = props;
    this.popup.visible = true;
    const isTurboSpin = setIsTurboSpin();
    this.spine.state.timeScale = isTurboSpin ? 2 : 1;
    this.spine.state.setAnimation(0, `particles_in_${props.inId}_${props.pattern}`, false);
    if (props.pattern !== CardAbilitiesPattern.Nup && props.pattern !== CardAbilitiesPattern.NupA) {
      this.spine.state.addAnimation(0, `${props.pattern}_in`, false, 0);
      this.spine.state.addAnimation(0, `${props.pattern}_loop`, true, 0);
    } else {
      this.spine.state.addAnimation(0, `${props.pattern}_in_${props.value}`, false, 0);
      this.spine.state.addAnimation(0, `${props.pattern}_loop_${props.value}`, true, 0);
    }
  }

  public hide(props: RewardsPopupProps): void {
    // OUT by id: Nup NupA Nx
    // OUT by pattern: NM Np NxA
    const isTurboSpin = setIsTurboSpin();
    this.spine.state.timeScale = isTurboSpin ? 2 : 1;
    if (props.pattern !== CardAbilitiesPattern.Nup && props.pattern !== CardAbilitiesPattern.NupA) {
      this.spine.state.setAnimation(0, `${props.pattern}_out`, false);
    } else {
      this.spine.state.setAnimation(0, `${props.pattern}_out_${props.value}`, false);
    }
    if (props.pattern === CardAbilitiesPattern.NM || props.pattern === CardAbilitiesPattern.Np) {
      this.spine.state.addAnimation(0, `particles_out_${props.pattern}`, false, 0);
    } else {
      this.spine.state.addAnimation(0, `particles_out_${props.outId}_${props.pattern}`, false, 0);
    }
  }
}

export default RarityPopupBackgroundAnimated;
