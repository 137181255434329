import _ from 'lodash';

import type { AudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  SFX_WIN_FeatureTrigger = 'SFX_WIN_FeatureTrigger',
  All_Multiply_Popup = 'All_Multiply_Popup',
  All_Upgrade_Popup = 'All_Upgrade_Popup',
  BGM_BG_Melo_Loop = 'BGM_BG_Melo_Loop',
  BGM_BP_Loop = 'BGM_BP_Loop',
  BigWin_End = 'BigWin_End',
  BigWin_Loop = 'BigWin_Loop',
  Bonus_Reward_Popup = 'Bonus_Reward_Popup',
  BP_ExitPopup = 'BP_ExitPopup',
  BuyButton = 'BuyButton',
  Card_Multiply_Popup = 'Card_Multiply_Popup',
  Cards_Disappear = 'Cards_Disappear',
  Card_Upgrade_Popup = 'Card_Upgrade_Popup',
  CDM_Symbol_Activation = 'CDM_Symbol_Activation',
  Common_Card_OpenFast = 'Common_Card_OpenFast',
  Common_Card_Open = 'Common_Card_Open',
  Epic_Card_OpenFast = 'Epic_Card_OpenFast',
  Epic_Card_Open = 'Epic_Card_Open',
  Eternal_Card_OpenFast = 'Eternal_Card_OpenFast',
  Eternal_Card_Open = 'Eternal_Card_Open',
  FP_EntrancePopup = 'FP_EntrancePopup',
  FreePack_OpenFast = 'FreePack_OpenFast',
  FreePack_Open = 'FreePack_Open',
  Grand_Card_OpenFast = 'Grand_Card_OpenFast',
  Grand_Card_Open = 'Grand_Card_Open',
  Hero_Symbol_1_Activation = 'Hero_Symbol_1_Activation',
  Hero_Symbol_2_Activation = 'Hero_Symbol_2_Activation',
  Hero_Symbol_3_Activation = 'Hero_Symbol_3_Activation',
  High_Pay_Symbol_1_Activation = 'High_Pay_Symbol_1_Activation',
  High_Pay_Symbol_2_Activation = 'High_Pay_Symbol_2_Activation',
  High_Pay_Symbol_3_Activation = 'High_Pay_Symbol_3_Activation',
  HighWin = 'HighWin',
  Legendary_Card_OpenFast = 'Legendary_Card_OpenFast',
  Legendary_Card_Open = 'Legendary_Card_Open',
  MasterPack_OpenFast = 'MasterPack_OpenFast',
  MasterPack_Open = 'MasterPack_Open',
  MediumWin = 'MediumWin',
  MP_EntrancePopup = 'MP_EntrancePopup',
  Mythic_Card_OpenFast = 'Mythic_Card_OpenFast',
  Mythic_Card_Open = 'Mythic_Card_Open',
  Rare_Card_OpenFast = 'Rare_Card_OpenFast',
  Rare_Card_Open = 'Rare_Card_Open',
  SmallWin = 'SmallWin',
  Uncommon_Card_OpenFast = 'Uncommon_Card_OpenFast',
  Uncommon_Card_Open = 'Uncommon_Card_Open',
  Win_Big = 'Win_Big',
  Win_Epic = 'Win_Epic',
  Win_Great = 'Win_Great',
  Win_Mega = 'Win_Mega',
  BottomCard = 'Bottom_Card',
  Disappear = 'Disappear',
  Pack_Open_Max = 'Pack_Open_Max',
  Pack_Open_Max_OpenFast = 'Pack_Open_Max_OpenFast',
  Pack_Open_Min = 'Pack_Open_Min',
  Pack_Open_Min_OpenFast = 'Pack_Open_Min_OpenFast',
  Pack_Open_Regular = 'Pack_Open_Regular',
  Pack_Open_Regular_OpenFast = 'Pack_Open_Regular_OpenFast',
  Particle_final = 'Particle_final',
  Particle_Land = 'Particle_Land',
  Particle_start = 'Particle_start',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  Phoenix_Anticipation = 'Phoenix_Anticipation',
  Ability_Activate = 'Ability_Activate',
}

export const audioSprite: AudioSprite = {
  [ISongs.Ability_Activate]: [0, 509.38775510204084],
  [ISongs.All_Multiply_Popup]: [2000, 5250.6122448979595],
  [ISongs.All_Upgrade_Popup]: [9000, 4517.732426303855],
  [ISongs.BGM_BG_Melo_Loop]: [15000, 222769.3424036281, true],
  [ISongs.BGM_BP_Loop]: [239000, 136267.75510204083, true],
  [ISongs.BigWin_End]: [377000, 3501.859410430825],
  [ISongs.BigWin_Loop]: [382000, 28447.346938775525, true],
  [ISongs.BottomCard]: [412000, 831.5646258503193],
  [ISongs.BP_ExitPopup]: [414000, 3501.859410430825],
  [ISongs.Card_Multiply_Popup]: [419000, 5250.6122448979795],
  [ISongs.Common_Card_Open]: [426000, 686.4399092970643],
  [ISongs.Common_Card_OpenFast]: [428000, 438.276643990946],
  [ISongs.Epic_Card_Open]: [430000, 2532.4263038548906],
  [ISongs.Epic_Card_OpenFast]: [434000, 1557.1882086167648],
  [ISongs.Eternal_Card_Open]: [437000, 2030.2947845805193],
  [ISongs.Eternal_Card_OpenFast]: [441000, 1214.693877551042],
  [ISongs.Grand_Card_Open]: [444000, 2018.6848072562498],
  [ISongs.Grand_Card_OpenFast]: [448000, 2021.5873015873171],
  [ISongs.Legendary_Card_Open]: [452000, 2017.2335600906877],
  [ISongs.Legendary_Card_OpenFast]: [456000, 1304.6712018140738],
  [ISongs.Mythic_Card_Open]: [459000, 2532.4263038548906],
  [ISongs.Mythic_Card_OpenFast]: [463000, 1554.2857142856974],
  [ISongs.Rare_Card_Open]: [466000, 2018.6848072562498],
  [ISongs.Rare_Card_OpenFast]: [470000, 2021.5873015873171],
  [ISongs.Uncommon_Card_Open]: [474000, 686.4399092970643],
  [ISongs.Uncommon_Card_OpenFast]: [476000, 438.276643990946],
  [ISongs.Card_Upgrade_Popup]: [478000, 3258.049886621336],
  [ISongs.CDM_Symbol_Activation]: [483000, 1250.9750566893558],
  [ISongs.Disappear]: [486000, 1748.7528344670977],
  [ISongs.FP_EntrancePopup]: [489000, 3272.562358276616],
  [ISongs.Hero_Symbol_1_Activation]: [494000, 1513.650793650811],
  [ISongs.Hero_Symbol_2_Activation]: [497000, 1005.7142857143049],
  [ISongs.Hero_Symbol_3_Activation]: [500000, 1250.9750566893558],
  [ISongs.High_Pay_Symbol_1_Activation]: [503000, 2021.5873015873171],
  [ISongs.High_Pay_Symbol_2_Activation]: [507000, 1001.3605442176754],
  [ISongs.High_Pay_Symbol_3_Activation]: [510000, 1002.8117913832375],
  [ISongs.MP_EntrancePopup]: [513000, 3272.562358276616],
  [ISongs.Pack_Open_Max]: [518000, 2218.956916099728],
  [ISongs.Pack_Open_Max_OpenFast]: [522000, 1500.5895691609794],
  [ISongs.Pack_Open_Min]: [525000, 3022.9478458049925],
  [ISongs.Pack_Open_Min_OpenFast]: [530000, 1269.8412698413222],
  [ISongs.Pack_Open_Regular]: [533000, 2239.2743764172565],
  [ISongs.Pack_Open_Regular_OpenFast]: [537000, 1252.426303854918],
  [ISongs.Particle_final]: [540000, 1789.387755101984],
  [ISongs.Particle_Land]: [543000, 785.1247165532413],
  [ISongs.Particle_start]: [545000, 1510.7482993197436],
  [ISongs.Phoenix_Anticipation]: [548000, 4033.333333333303],
  [ISongs.SFX_SM_CountUp_End]: [554000, 933.4693877550535],
  [ISongs.SFX_SM_CountUp_Loop]: [556000, 399.773242630431, true],
  [ISongs.SFX_UI_AutoSpin]: [558000, 320.4988662131427],
  [ISongs.SFX_UI_BetChange]: [560000, 32.10884353745769],
  [ISongs.SFX_UI_Close]: [562000, 200.6802721087979],
  [ISongs.SFX_UI_General]: [564000, 88.34467120186673],
  [ISongs.SFX_UI_Hover]: [566000, 151.7913832199156],
  [ISongs.SFX_UI_MaxBet]: [568000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [570000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [572000, 249.61451247168043],
  [ISongs.Win_Big]: [574000, 1622.3582766440359],
  [ISongs.Win_Epic]: [577000, 1862.2675736961583],
  [ISongs.SFX_WIN_FeatureTrigger]: [580000, 1033.333333333303],
  [ISongs.Win_Great]: [583000, 2118.412698412726],
  [ISongs.Win_Mega]: [587000, 1696.190476190509],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Melo_Loop]: 0.2,
  [ISongs.BuyButton]: 0.2,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.HighWin]: 0.3,
  [ISongs.MediumWin]: 0.3,
  [ISongs.SmallWin]: 0.3,
  [ISongs.Win_Big]: 0.3,
  [ISongs.Win_Mega]: 0.3,
  [ISongs.Win_Great]: 0.3,
  [ISongs.Win_Epic]: 0.4,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.6,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.133,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.3,
  [ISongs.All_Multiply_Popup]: 0.3,
  [ISongs.All_Upgrade_Popup]: 0.3,
  [ISongs.BGM_BP_Loop]: 0.3,
  [ISongs.Bonus_Reward_Popup]: 0.3,
  [ISongs.BP_ExitPopup]: 0.3,
  [ISongs.Card_Multiply_Popup]: 0.3,
  [ISongs.Cards_Disappear]: 0.3,
  [ISongs.Card_Upgrade_Popup]: 0.3,
  [ISongs.CDM_Symbol_Activation]: 0.5,
  [ISongs.Common_Card_OpenFast]: 0.3,
  [ISongs.Common_Card_Open]: 0.3,
  [ISongs.Epic_Card_OpenFast]: 0.3,
  [ISongs.Epic_Card_Open]: 0.3,
  [ISongs.Eternal_Card_OpenFast]: 0.3,
  [ISongs.Eternal_Card_Open]: 0.3,
  [ISongs.FP_EntrancePopup]: 0.3,
  [ISongs.FreePack_OpenFast]: 0.3,
  [ISongs.FreePack_Open]: 0.3,
  [ISongs.Grand_Card_OpenFast]: 0.3,
  [ISongs.Grand_Card_Open]: 0.3,
  [ISongs.Hero_Symbol_1_Activation]: 0.5,
  [ISongs.Hero_Symbol_2_Activation]: 0.5,
  [ISongs.Hero_Symbol_3_Activation]: 0.5,
  [ISongs.High_Pay_Symbol_1_Activation]: 0.5,
  [ISongs.High_Pay_Symbol_2_Activation]: 0.5,
  [ISongs.High_Pay_Symbol_3_Activation]: 0.5,
  [ISongs.Legendary_Card_OpenFast]: 0.3,
  [ISongs.Legendary_Card_Open]: 0.3,
  [ISongs.MasterPack_OpenFast]: 0.3,
  [ISongs.MasterPack_Open]: 0.3,
  [ISongs.MP_EntrancePopup]: 0.3,
  [ISongs.Mythic_Card_OpenFast]: 0.3,
  [ISongs.Mythic_Card_Open]: 0.3,
  [ISongs.Rare_Card_OpenFast]: 0.3,
  [ISongs.Rare_Card_Open]: 0.3,
  [ISongs.Uncommon_Card_OpenFast]: 0.3,
  [ISongs.Uncommon_Card_Open]: 0.3,
  [ISongs.BottomCard]: 0.3,
  [ISongs.Pack_Open_Max]: 0.3,
  [ISongs.Pack_Open_Min]: 0.3,
  [ISongs.Pack_Open_Regular]: 0.3,
  [ISongs.Pack_Open_Max_OpenFast]: 0.3,
  [ISongs.Pack_Open_Min_OpenFast]: 0.3,
  [ISongs.Pack_Open_Regular_OpenFast]: 0.3,
  [ISongs.Particle_Land]: 0.3,
  [ISongs.Particle_final]: 0.3,
  [ISongs.Particle_start]: 0.3,
  [ISongs.Disappear]: 0.3,
  [ISongs.Phoenix_Anticipation]: 0.3,
  [ISongs.Ability_Activate]: 0.4,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
