import type React from 'react';
import { useTranslation } from 'react-i18next';

import { CardAbilitiesPattern, CardClass, CardLevel, SlotId } from '../../config';
import {
  abilitiesDescription,
  abilitiesPreferences,
  cardAbilities,
  cardTypeNames,
} from '../../slotMachine/collection/collection.model';
import { getSlotPrice } from '../../slotMachine/collection/getSlotPrice';

import styles from './info.module.scss';

const cardDescriptionPlatePositions: { [key in CardLevel]: string } = {
  Cm: '1px 1px',
  Un: `-424px 1px`,
  Ra: `-847px 1px`,
  My: `1px -591px`,
  Ep: `-424px -591px`,
  Gr: `-847px -591px`,
  Le: `-1270px 1px`,
  Et: `-1270px -591px`,
};
const cardPositions: Record<CardLevel, string> = cardDescriptionPlatePositions;

const cardAbilitiesPositions: { [key in CardAbilitiesPattern]: { position: string; width: number; height: number } } = {
  Np: {
    position: '-71px 0',
    width: 65,
    height: 64,
  },
  NM: {
    position: '0 0',
    width: 71,
    height: 70,
  },
  Nx: {
    position: '0 -70px',
    width: 64,
    height: 64,
  },
  NxA: {
    position: '-136px -70px',
    width: 72,
    height: 72,
  },
  Nup: {
    position: '-71px -64px',
    width: 65,
    height: 64,
  },
  NupA: {
    position: '-136px 0',
    width: 70,
    height: 70,
  },
};

const cardAbilitiesBackgroundUrl = 'cards/abilities/abilities.png';
const cardDescriptionPlateUrl = 'cards/cardsAbilities/cardsAbilities.png';

const cardImageUrls = Object.values(CardClass).reduce(
  (urls, cardType) => ({ ...urls, [cardType]: `cards/cardsCollection/${cardType}.png` }),
  {},
) as Record<CardClass, string>;

function getBackground(url: string, position: string): string {
  return `url(${url}) ${position}`;
}

function getAbilities(slotId: SlotId): JSX.Element {
  return (
    <div className={styles['abilities']}>
      {cardAbilities[slotId]?.map(({ pattern, value }, index) => (
        <div
          className={styles['ability']}
          key={index}
          style={{
            height: cardAbilitiesPositions[pattern].height,
            width: cardAbilitiesPositions[pattern].width,
            background: getBackground(cardAbilitiesBackgroundUrl, cardAbilitiesPositions[pattern].position),
          }}
        >
          <span>{abilitiesPreferences[pattern].prefix + value}</span>
          {abilitiesPreferences[pattern].label && (
            <span className={styles['ability-type']} style={{ color: abilitiesPreferences[pattern].color }}>
              {abilitiesPreferences[pattern].label}
            </span>
          )}
        </div>
      ))}
    </div>
  );
}

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['paytable-cards']}>
      {Object.values(CardClass).map((type) =>
        Object.values(CardLevel).map((level, idx) => (
          <div
            key={level + type}
            className={`${styles['paytable-card']} ${
              idx > 0 && idx < Object.values(CardLevel).length ? styles['margin'] : ''
            }`}
          >
            <div
              className={styles['cards']}
              style={{
                height: 590,
                width: 422,
                background: getBackground(cardImageUrls[type], cardPositions[level]),
              }}
            >
              {getAbilities((level + type) as SlotId)}
            </div>

            <div className={styles['description']}>
              <div
                className={styles['description-plate']}
                style={{
                  height: 588,
                  width: 421,
                  background: getBackground(cardDescriptionPlateUrl, cardPositions[level]),
                }}
              >
                <div className={styles['card-name']}>{t(cardTypeNames[type])}</div>
                {getAbilities((level + type) as SlotId)}
                <div className={styles['ability-description']}>
                  {cardAbilities[level + type]?.map(({ pattern, value }, index) => (
                    <div
                      className={styles[pattern]}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: t(abilitiesDescription[pattern], { value }) }}
                    ></div>
                  ))}
                </div>
              </div>
              <div className={styles['cost']}>{getSlotPrice((level + type) as SlotId)}</div>
            </div>
          </div>
        )),
      )}
    </div>
  );
};

export default PaytableComponent;
