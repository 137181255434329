import type { EnterProps, GameMode, ISettledBet } from '../../global';
import type { States } from '../config';

export abstract class Controller {
  public abstract gameMode: GameMode;

  public enterInitState(_prevState: States): void {}

  public exitInitState(_nextState: States): void {}

  public enterBrokenGameState(_prevState: States): void {}

  public exitBrokenGameState(_nextState: States): void {}

  public enterIntroState(_prevState: States): void {}

  public exitIntroState(_nextState: States): void {}

  public enterIdleState(_prevState: States): void {}

  public exitIdleState(_nextState: States): void {}

  public enterOpenPackState(_prevState: States): void {}

  public exitOpenPackState(_nextState: States): void {}

  public enterBeforeWinState(_prevState: States): void {}

  public exitBeforeWinState(_nextState: States): void {}

  public enterAppendAbilitiesState(_prevState: States): void {}

  public exitAppendAbilitiesState(_nextState: States): void {}

  public enterWinPresentationState(_prevState: States): void {}

  public exitWinPresentationState(_nextState: States): void {}

  public enterAfterWinState(_prevState: States): void {}

  public exitAfterWinState(_nextState: States): void {}

  public enterJingleState(_prevState: States): void {}

  public exitJingleState(_nextState: States): void {}

  public enterTransitionState(_prevState: States): void {}

  public exitTransitionState(_nextState: States): void {}

  public enterController(_prevGameMode: GameMode | null, _props?: EnterProps): void {}

  public exitController(_nextGameMode: GameMode): void {}

  public setResult(_result: ISettledBet): void {}
}
