import { Container, Text, TextStyle } from 'pixi.js';

import { CardClass, CardLevel, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { ModalService } from '../ModalService';
import { eventManager } from '../config';
import LibraryBtn from '../controlButtons/libraryBtn';

import { CardInfo } from './cardInfo';
import { SMALL_CARD_HEIGHT, SMALL_CARD_WIDTH, levelNameTextStyle, levelNames } from './collection.model';
import { CollectionCard } from './collectionCard';
import { ScrollBox } from './scrollBox';

export class Collection extends Container {
  public override name = 'Collection';
  constructor(private newCards: SlotId[] = []) {
    super();
    const contentContainer = new Container();
    const scrollBox = new ScrollBox(this.getCardContainers());
    scrollBox.y = 50;
    const header = this.getHeader();

    contentContainer.addChild(scrollBox, header);

    this.addChild(this.getLibraryBtn(), contentContainer);
    this.setContainerSizes(contentContainer, window.innerWidth, window.innerHeight);

    eventManager.addListener(EventTypes.PARENT_MODAL_RESIZE, (width, height) => {
      this.setContainerSizes(contentContainer, width, height);
    });
  }

  private getLibraryBtn(): Container {
    const libraryBtn = new LibraryBtn(() => ModalService.the.hide());
    libraryBtn.setDisable(false);

    // Workaround need to render controlButton, problem with realization
    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
    return libraryBtn;
  }
  private getHeader(): Container {
    const headerContainer = new Container();
    headerContainer.name = 'header';
    Object.values(levelNames).forEach((levelName, index) => {
      const text = new Text(levelName, levelNameTextStyle as TextStyle);
      this.setCardPosition(text, index);
      text.x += (SMALL_CARD_WIDTH - text.width) / 2;
      headerContainer.addChild(text);
    });

    return headerContainer;
  }
  private getCardContainers(): Container {
    const cardsContainer = new Container();
    cardsContainer.name = 'Cards';

    Object.values(CardClass).forEach((type, index) => {
      const typeContainer = new Container();
      cardsContainer.addChild(typeContainer);

      Object.values(CardLevel).forEach((level, index) => {
        const isNewUnlockedCard = this.newCards.includes((level + type) as SlotId);
        const card = new CollectionCard(level, type, isNewUnlockedCard, () => this.openCardInfo(level, type), {
          width: SMALL_CARD_WIDTH,
          height: SMALL_CARD_HEIGHT,
        });
        typeContainer.addChild(card);
        this.setCardPosition(card, index);
      });

      this.setCardRowPosition(typeContainer, index);
    });

    return cardsContainer;
  }
  private openCardInfo(level: CardLevel, type: CardClass): void {
    ModalService.the.open(new CardInfo(level, type), { title: 'collection' });
  }
  private setCardRowPosition(row: Container, index: number): void {
    const rowMargin = 16;
    row.y = index * (row.height + rowMargin);
  }
  private setCardPosition(card: Container, index: number): void {
    const cardMargin = 32;

    card.x = index * (SMALL_CARD_WIDTH + cardMargin);
  }
  private setContainerSizes(container: Container, width: number, height: number): void {
    if (this.children.length) {
      const yScale = height / 1080;
      const xScale = width / 1500;
      container.scale.set(Math.min(yScale, xScale, 1));
      container.y = (200 * height) / 1080;
      container.x = (width - container.width) / 2;
    } else {
      eventManager.removeListener(EventTypes.PARENT_MODAL_RESIZE);
    }
  }
}
