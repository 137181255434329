import { TextStyle } from 'pixi.js';

export const tooltipTextStyle = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 27,
  fontWeight: 'bold',
  leading: 1,
  lineJoin: 'round',
  whiteSpace: 'normal',
  stroke: '#000000',
  strokeThickness: 4,
});

export enum Tooltips {
  cards = 'tooltip-cards',
  collection = 'tooltip-collection',
  reward = 'tooltip-reward',
  spin = 'tooltip-spin',
}
