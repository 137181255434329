import * as PIXI from 'pixi.js';

import type { GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';

// import { SLOTS_CONTAINER_WIDTH } from '../config';

class Logo extends ViewContainer {
  public gameLogo: PIXI.Sprite;

  constructor() {
    super();
    this.gameLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.gameLogo));

    this.addChild(this.gameLogo);
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {}

  // override resize(_width: number, _height: number): void {
  // this.gameLogo.scale.set(1);
  // this.gameLogo.x = SLOTS_CONTAINER_WIDTH / 2 - this.gameLogo.width / 2;
  // this.gameLogo.y = -40;
  // }
}

export default Logo;
