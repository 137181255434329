import MultiStyleText from 'pixi-multistyle-text';
import { Container, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setEnabledCards, setIsOpenMenuModal, setIsPopupOpened } from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { ResourceTypes } from '../../resources.d';
import { calcPercentage, canPressSpin } from '../../utils';
import { eventManager } from '../config';
import { TimeActions } from '../tooltips/timeActions';
import { Tooltip } from '../tooltips/tooltip';
import { Tooltips } from '../tooltips/tooltips.model';

import { ControlButton } from './index';

class LibraryBtn extends ControlButton {
  private isAutoSpins: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private prevUnlockedCards: SlotId[];
  public newIcon = this.getNewLabel();
  private tooltip = this.getTooltip();
  private collectionCount = new Container();

  constructor(private onClick: (newCards: SlotId[]) => void = () => {}) {
    super('library');
    this.interactive = !this.isDisabled;
    this.isAutoSpins = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.on('pointertap', () => this.onClickWithNewCards());
    this.initSubscriptions();
    this.handleDisable();
    this.prevUnlockedCards = setEnabledCards();
    this.btn.addChild(this.newIcon, this.tooltip);
    this.hideNewLabel();
    this.setCount(setEnabledCards().length, Object.values(SlotId).length);

    TimeActions.the.executeAfterNewCard(15, () => this.tooltip.show());

    eventManager.addListener(EventTypes.CARD_IS_OPENED, () => {
      if (this.children.length) {
        this.setCount(setEnabledCards().length, Object.values(SlotId).length);
      }
    });
    eventManager.addListener(EventTypes.GET_NEW_CARD, () => {
      if (this.children.length) {
        this.showNewLabel();
      }
    });
    eventManager.addListener(EventTypes.CHANGE_STATE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });

    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
  }

  public getTooltip(): Tooltip {
    const tooltip = new Tooltip(Tooltips.collection, 'tooltipCollection');

    tooltip.position.set(-240, -tooltip.height - 40);

    return tooltip;
  }

  private showNewLabel(): void {
    this.newIcon.visible = true;
  }

  private hideNewLabel(): void {
    this.newIcon.visible = false;
  }

  private getNewLabel(): Sprite {
    const newIcon = new Sprite(Texture.from(ResourceTypes.newIcon));
    newIcon.y = -40;

    return newIcon;
  }

  private onClickWithNewCards(): void {
    if (this.isDisabled || setIsPopupOpened() || setIsOpenMenuModal().isOpen) return;
    AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
    const newUnlockedCards = setEnabledCards().filter((card) => !this.prevUnlockedCards.includes(card));
    this.prevUnlockedCards = setEnabledCards();
    this.hideNewLabel();
    this.tooltip.hide();

    this.onClick(newUnlockedCards);
  }

  public setCount(openedCards: number, totalCards: number): void {
    const info = new MultiStyleText(`${openedCards} <total>/ ${totalCards}</total>`, {
      default: {
        fill: 'white',
        fontSize: 20,
        whiteSpace: 'normal',
        fontWeight: '700',
        align: 'center',
        stroke: '#000000',
        strokeThickness: 6,
        letterSpacing: 0.1,
      },
      total: {
        fill: '#777',
      },
    });
    this.collectionCount.destroy();
    this.collectionCount = new Container();
    this.collectionCount.addChild(info);
    this.addChild(this.collectionCount);
    this.collectionCount.y = -10;
    this.collectionCount.x = -this.btn.scale.x * 130;
    this.collectionCount.scale.set(this.btn.scale.x, this.btn.scale.y);
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {}

  private initSubscriptions = (): void => {};

  private handleDisable = (): void => {
    if (this.children.length) {
      const isDisable = !canPressSpin({
        gameMode: Logic.the.controller.gameMode,
        slotState: Logic.the.state.name,
        isSlotBusy: this.isSlotBusy,
        isPopupOpened: setIsPopupOpened(),
      });

      this.setDisable(isDisable || this.isAutoSpins || Logic.the.state.name !== States.IDLE);
    }
  };

  public override handlePosition(): void {
    if (this.children.length) {
      let x = calcPercentage(this.applicationSize.width, 27);
      let y = calcPercentage(this.applicationSize.height, 95);
      this.setSize(calcPercentage(this.applicationSize.width, 4));

      if (this.isPortraitMode) {
        this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 20));
        x = calcPercentage(this.applicationSize.width, 87.5);
        y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 25);
      }
      if (this.isLandscapeMode) {
        this.setSize(calcPercentage(this.applicationSize.height, 8));
        x = calcPercentage(this.applicationSize.width, 27);
        y = calcPercentage(this.applicationSize.height, 87.5);
      }
      this.collectionCount.x = -this.btn.scale.x * 130;
      this.collectionCount.scale.set(this.btn.scale.x, this.btn.scale.y);
      this.x = x;
      this.y = y;
    } else {
      this.removeAllListeners();
    }
  }
}

export default LibraryBtn;
