import { Loader, Texture } from 'pixi.js';

import { CardLevel } from '../../config';

export const bgByRewardName: Record<CardLevel, () => Texture | undefined> = {
  [CardLevel.Cm]: () => Loader.shared.resources['cardBackSprite']!.spritesheet!.textures['common'],
  [CardLevel.Ep]: () => Loader.shared.resources['cardBackSprite']!.spritesheet!.textures['epic'],
  [CardLevel.Et]: () => Loader.shared.resources['cardBackSprite']!.spritesheet!.textures['eternal'],
  [CardLevel.Gr]: () => Loader.shared.resources['cardBackSprite']!.spritesheet!.textures['grand'],
  [CardLevel.Le]: () => Loader.shared.resources['cardBackSprite']!.spritesheet!.textures['legendary'],
  [CardLevel.Ra]: () => Loader.shared.resources['cardBackSprite']!.spritesheet!.textures['neon'],

  [CardLevel.Un]: () => Loader.shared.resources['cardBackSprite']!.spritesheet!.textures['common'],
  [CardLevel.My]: () => Loader.shared.resources['cardBackSprite']!.spritesheet!.textures['common'],
};
