import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Texture } from 'pixi.js';

import { CardLevel } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setSelectedRewardTable } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

const bgByRewardName: Record<CardLevel, () => Texture> = {
  [CardLevel.Cm]: () => Texture.from(ResourceTypes.slotBgBase),
  [CardLevel.My]: () => Texture.from(ResourceTypes.slotBgNeon),
  [CardLevel.Ep]: () => Texture.from(ResourceTypes.slotBgGold),
  [CardLevel.Et]: () => Texture.from(ResourceTypes.slotBgRainbow),
  [CardLevel.Gr]: () => Texture.from(ResourceTypes.slotBgIce),
  [CardLevel.Le]: () => Texture.from(ResourceTypes.slotBgRed),

  [CardLevel.Un]: () => Texture.from(ResourceTypes.slotBgBase),
  [CardLevel.Ra]: () => Texture.from(ResourceTypes.slotBgBase),
};

class Background extends ViewContainer {
  private bgSprite = new PIXI.Sprite();

  private ambientBaseAnimation: Spine | null = null;

  private appHeight!: number;

  private appWidth!: number;

  private gameMode = GameMode.BASE_GAME;

  get getBackground(): Texture {
    const rewardName = setSelectedRewardTable();
    return bgByRewardName[`${rewardName}`]();
  }

  constructor() {
    super();
    // set initial background
    this.bgSprite.texture = this.getBackground;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.initAnimation();
    eventManager.on(EventTypes.UPDATE_BACKGROUND, () => {
      this.bgSprite.texture = this.getBackground;
    });
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.gameMode = settings.mode;
    this.changeBg();
  }

  private changeBg(): void {
    this.bgSprite.texture = this.getBackground;
    this.ambientBaseAnimation?.position.set(0, 0);
  }

  private initAnimation(): void {
    this.ambientBaseAnimation = new Spine(PIXI.Loader.shared.resources['ambient']!.spineData!);

    this.ambientBaseAnimation.state.setAnimation(0, 'background_original', true);
    this.addChild(this.ambientBaseAnimation);
  }

  public override resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;
    this.appWidth = width;
    this.appHeight = height;
    this.changeBg();
    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
