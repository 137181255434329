import { States } from '../config';
import { Logic } from '../index';

import { State } from './State';

export class PackOpen extends State {
  public name: States = States.PACK_OPEN;

  public nodes: Map<States, State> = new Map();

  public static the = new PackOpen();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterOpenPackState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitOpenPackState(nextState);
  }
}
