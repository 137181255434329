import { EventTypes } from '../global.d';

import { ISongs } from './audio';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [20, 50, 75, 100],
    minBet: 20,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      bet: 'bet',
      win: 'win',
    },
  },
};

export enum SlotId {
  CmLP1 = 'CmLP1',
  UnLP1 = 'UnLP1',
  RaLP1 = 'RaLP1',
  MyLP1 = 'MyLP1',
  EpLP1 = 'EpLP1',
  GrLP1 = 'GrLP1',
  LeLP1 = 'LeLP1',
  EtLP1 = 'EtLP1',
  CmLP2 = 'CmLP2',
  UnLP2 = 'UnLP2',
  RaLP2 = 'RaLP2',
  MyLP2 = 'MyLP2',
  EpLP2 = 'EpLP2',
  GrLP2 = 'GrLP2',
  LeLP2 = 'LeLP2',
  EtLP2 = 'EtLP2',
  CmLP3 = 'CmLP3',
  UnLP3 = 'UnLP3',
  RaLP3 = 'RaLP3',
  MyLP3 = 'MyLP3',
  EpLP3 = 'EpLP3',
  GrLP3 = 'GrLP3',
  LeLP3 = 'LeLP3',
  EtLP3 = 'EtLP3',
  CmMP1 = 'CmMP1',
  UnMP1 = 'UnMP1',
  RaMP1 = 'RaMP1',
  MyMP1 = 'MyMP1',
  EpMP1 = 'EpMP1',
  GrMP1 = 'GrMP1',
  LeMP1 = 'LeMP1',
  EtMP1 = 'EtMP1',
  CmMP2 = 'CmMP2',
  UnMP2 = 'UnMP2',
  RaMP2 = 'RaMP2',
  MyMP2 = 'MyMP2',
  EpMP2 = 'EpMP2',
  GrMP2 = 'GrMP2',
  LeMP2 = 'LeMP2',
  EtMP2 = 'EtMP2',
  CmMP3 = 'CmMP3',
  UnMP3 = 'UnMP3',
  RaMP3 = 'RaMP3',
  MyMP3 = 'MyMP3',
  EpMP3 = 'EpMP3',
  GrMP3 = 'GrMP3',
  LeMP3 = 'LeMP3',
  EtMP3 = 'EtMP3',
  CmHP1 = 'CmHP1',
  UnHP1 = 'UnHP1',
  RaHP1 = 'RaHP1',
  MyHP1 = 'MyHP1',
  EpHP1 = 'EpHP1',
  GrHP1 = 'GrHP1',
  LeHP1 = 'LeHP1',
  EtHP1 = 'EtHP1',
  CmHP2 = 'CmHP2',
  UnHP2 = 'UnHP2',
  RaHP2 = 'RaHP2',
  MyHP2 = 'MyHP2',
  EpHP2 = 'EpHP2',
  GrHP2 = 'GrHP2',
  LeHP2 = 'LeHP2',
  EtHP2 = 'EtHP2',
  CmHP3 = 'CmHP3',
  UnHP3 = 'UnHP3',
  RaHP3 = 'RaHP3',
  MyHP3 = 'MyHP3',
  EpHP3 = 'EpHP3',
  GrHP3 = 'GrHP3',
  LeHP3 = 'LeHP3',
  EtHP3 = 'EtHP3',
  CmHE1 = 'CmHE1',
  UnHE1 = 'UnHE1',
  RaHE1 = 'RaHE1',
  MyHE1 = 'MyHE1',
  EpHE1 = 'EpHE1',
  GrHE1 = 'GrHE1',
  LeHE1 = 'LeHE1',
  EtHE1 = 'EtHE1',
  CmHE2 = 'CmHE2',
  UnHE2 = 'UnHE2',
  RaHE2 = 'RaHE2',
  MyHE2 = 'MyHE2',
  EpHE2 = 'EpHE2',
  GrHE2 = 'GrHE2',
  LeHE2 = 'LeHE2',
  EtHE2 = 'EtHE2',
  CmHE3 = 'CmHE3',
  UnHE3 = 'UnHE3',
  RaHE3 = 'RaHE3',
  MyHE3 = 'MyHE3',
  EpHE3 = 'EpHE3',
  GrHE3 = 'GrHE3',
  LeHE3 = 'LeHE3',
  EtHE3 = 'EtHE3',
  CmTCM = 'CmTCM',
  UnTCM = 'UnTCM',
  RaTCM = 'RaTCM',
  MyTCM = 'MyTCM',
  EpTCM = 'EpTCM',
  GrTCM = 'GrTCM',
  LeTCM = 'LeTCM',
  EtTCM = 'EtTCM',
}

export enum CardClass {
  LP1 = 'LP1',
  LP2 = 'LP2',
  LP3 = 'LP3',
  MP1 = 'MP1',
  MP2 = 'MP2',
  MP3 = 'MP3',
  HP1 = 'HP1',
  HP2 = 'HP2',
  HP3 = 'HP3',
  HE1 = 'HE1',
  HE2 = 'HE2',
  HE3 = 'HE3',
  TCM = 'TCM',
}

export enum CardLevel {
  Cm = 'Cm',
  Un = 'Un',
  Ra = 'Ra',
  My = 'My',
  Ep = 'Ep',
  Gr = 'Gr',
  Le = 'Le',
  Et = 'Et',
}

export enum CardAbilitiesPattern {
  Np = 'Np', // Add N Free Packs.
  NM = 'NM', // Add N Master Packs.
  Nx = 'Nx', // Multiply one random card’s reward by Nx.
  NxA = 'NxA', // Multiply all cards rewards by Nx.
  Nup = 'Nup', // Upgrade one other random card's rarity by N.
  NupA = 'NupA', // Upgrade all other cards rarities by N.
}

type SoundsPerCardLevelType = {
  [key in CardLevel]: (isTurboSpin: boolean) => ISongs;
};

// tmp sounds, 1 sound for 2, will change namings later.
export const soundsPerCardLevel: SoundsPerCardLevelType = {
  Cm: (isTurboSpin: boolean) => (isTurboSpin ? ISongs.Common_Card_OpenFast : ISongs.Common_Card_Open),
  Un: (isTurboSpin: boolean) => (isTurboSpin ? ISongs.Uncommon_Card_OpenFast : ISongs.Uncommon_Card_Open),
  Ra: (isTurboSpin: boolean) => (isTurboSpin ? ISongs.Rare_Card_OpenFast : ISongs.Rare_Card_Open),
  My: (isTurboSpin: boolean) => (isTurboSpin ? ISongs.Mythic_Card_OpenFast : ISongs.Mythic_Card_Open),
  Ep: (isTurboSpin: boolean) => (isTurboSpin ? ISongs.Epic_Card_OpenFast : ISongs.Epic_Card_Open),
  Gr: (isTurboSpin: boolean) => (isTurboSpin ? ISongs.Grand_Card_OpenFast : ISongs.Grand_Card_Open),
  Le: (isTurboSpin: boolean) => (isTurboSpin ? ISongs.Legendary_Card_OpenFast : ISongs.Legendary_Card_Open),
  Et: (isTurboSpin: boolean) => (isTurboSpin ? ISongs.Eternal_Card_OpenFast : ISongs.Eternal_Card_Open),
};

export enum eventPatternMappingsAnimationStart {
  NM_1 = EventTypes.ABILITIES_ANIMATION_START_NM_1,
  NM_2 = EventTypes.ABILITIES_ANIMATION_START_NM_2,
  NM_3 = EventTypes.ABILITIES_ANIMATION_START_NM_3,
  NM_4 = EventTypes.ABILITIES_ANIMATION_START_NM_4,
  NM_5 = EventTypes.ABILITIES_ANIMATION_START_NM_5,
  Np_1 = EventTypes.ABILITIES_ANIMATION_START_NP_1,
  Np_2 = EventTypes.ABILITIES_ANIMATION_START_NP_2,
  Np_3 = EventTypes.ABILITIES_ANIMATION_START_NP_3,
  Np_4 = EventTypes.ABILITIES_ANIMATION_START_NP_4,
  Np_5 = EventTypes.ABILITIES_ANIMATION_START_NP_5,
  Nup_1 = EventTypes.ABILITIES_ANIMATION_START_NUP_1,
  Nup_2 = EventTypes.ABILITIES_ANIMATION_START_NUP_2,
  Nup_3 = EventTypes.ABILITIES_ANIMATION_START_NUP_3,
  Nup_4 = EventTypes.ABILITIES_ANIMATION_START_NUP_4,
  Nup_5 = EventTypes.ABILITIES_ANIMATION_START_NUP_5,
  NupA_1 = EventTypes.ABILITIES_ANIMATION_START_NUPA_1,
  NupA_2 = EventTypes.ABILITIES_ANIMATION_START_NUPA_2,
  NupA_3 = EventTypes.ABILITIES_ANIMATION_START_NUPA_3,
  NupA_4 = EventTypes.ABILITIES_ANIMATION_START_NUPA_4,
  NupA_5 = EventTypes.ABILITIES_ANIMATION_START_NUPA_5,
  Nx_1 = EventTypes.ABILITIES_ANIMATION_START_NX_1,
  Nx_2 = EventTypes.ABILITIES_ANIMATION_START_NX_2,
  Nx_3 = EventTypes.ABILITIES_ANIMATION_START_NX_3,
  Nx_4 = EventTypes.ABILITIES_ANIMATION_START_NX_4,
  Nx_5 = EventTypes.ABILITIES_ANIMATION_START_NX_5,
  NxA_1 = EventTypes.ABILITIES_ANIMATION_START_XNA_1,
  NxA_2 = EventTypes.ABILITIES_ANIMATION_START_XNA_2,
  NxA_3 = EventTypes.ABILITIES_ANIMATION_START_XNA_3,
  NxA_4 = EventTypes.ABILITIES_ANIMATION_START_XNA_4,
  NxA_5 = EventTypes.ABILITIES_ANIMATION_START_XNA_5,
}

export enum eventPatternMappingsAnimationEnd {
  NM = EventTypes.ABILITIES_ANIMATION_END_NM,
  Np = EventTypes.ABILITIES_ANIMATION_END_NP,
  Nup = EventTypes.ABILITIES_ANIMATION_END_NUP,
  NupA = EventTypes.ABILITIES_ANIMATION_END_NUPA,
  Nx = EventTypes.ABILITIES_ANIMATION_END_NX,
  NxA = EventTypes.ABILITIES_ANIMATION_END_XNA,
}
