import { ApolloClient, HttpLink, InMemoryCache, ServerParseError, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { ApplicationError, EErrorCodeClient, EErrorKind } from '@phoenix7dev/common-errors';

import { EventTypes } from '../global.d';
import i18n from '../i18next';
import { eventManager } from '../slotMachine/config';

import { setIsRevokeThrowingError, setSlotConfig, setStressful } from './cache';
import { isStoppedGql } from './query';
import typePolicies from './typePolices';

const REST_URL = process.env['REACT_APP_URL'] as string;
const ERROR_CODES = [503, 502];
const { NETWORK_RETRY_ATTEMPTS = 5, NETWORK_RETRY_DELAY = 1000 } = window.__ENV__;
const RETRY_OPERATIONS = ['PlaceBet'];

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const { retryCount } = operation.getContext();
  const statusCode = (networkError as ServerParseError)?.statusCode;
  if (RETRY_OPERATIONS.includes(operation.operationName) && ERROR_CODES.includes(statusCode)) {
    if (typeof retryCount === 'undefined' || retryCount < NETWORK_RETRY_ATTEMPTS) {
      forward(operation);
      return;
    }
  }

  if (setIsRevokeThrowingError()) return;
  if (graphQLErrors) {
    setIsRevokeThrowingError(true);
    eventManager.emit(EventTypes.ROLLBACK_STATE);
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      const { message, extensions } = err;
      setIsRevokeThrowingError(true);
      const e = ApplicationError.getShapeByAppCode(extensions?.['applicationCode']);
      if (e.kind === EErrorKind.CLIENT) {
        if (e.code === EErrorCodeClient.INSUFFICIENT_FUNDS) {
          setStressful({
            show: true,
            type: 'balance',
            message: i18n.t([extensions?.['i18nKey'], 'errors.UNKNOWN.UNKNOWN']) || message,
          });
          return;
        }
      }
      setStressful({
        show: true,
        type: 'network',
        message:
          i18n.t([
            (extensions && (extensions['i18nKey'] as string)) || 'errors.UNKNOWN.UNKNOWN',
            'errors.UNKNOWN.UNKNOWN',
          ]) || message,
      });
    }
  } else if (networkError) {
    setIsRevokeThrowingError(true);
    eventManager.emit(EventTypes.ROLLBACK_STATE);
    setStressful({
      show: true,
      type: 'network',
      message: i18n.t('errors.UNKNOWN.NETWORK'),
    });
  } else {
    setIsRevokeThrowingError(true);
    eventManager.emit(EventTypes.ROLLBACK_STATE);
    setStressful({
      show: true,
      type: 'network',
      message: i18n.t('errors.UNKNOWN.UNKNOWN'),
    });
  }
});

const connectionParams = () => {
  const { sessionId } = setSlotConfig();
  return {
    Authorization: sessionId,
  };
};

const authLink = setContext((_) => {
  return {
    headers: {
      ...connectionParams(),
    },
  };
});

const httpLink = new HttpLink({
  uri: REST_URL,
});

const retryLink = new RetryLink({
  delay: {
    initial: NETWORK_RETRY_DELAY,
    max: NETWORK_RETRY_DELAY,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    const status = error?.networkError?.statusCode || error?.statusCode;
    const { operationName } = operation;
    if (count <= NETWORK_RETRY_ATTEMPTS) {
      operation.setContext((context: Record<string, unknown>) => ({ ...context, retryCount: count }));
      return RETRY_OPERATIONS.includes(operationName) && ERROR_CODES.includes(status);
    }
    return false;
  },
});

const cache = new InMemoryCache({
  typePolicies,
});

cache.writeQuery({
  query: isStoppedGql,
  data: {
    isSlotStopped: true,
  },
});

export const client = new ApolloClient({
  link: authLink.concat(from([retryLink, errorLink, httpLink])),
  cache,
});

export default client;
