import MultiStyleText from 'pixi-multistyle-text';
import { Container, Loader, Sprite } from 'pixi.js';

import type { CardClass, CardLevel, SlotId } from '../../config';
import i18n from '../../i18next';
import { TextField } from '../components/TextField';
import type { ICardAbility } from '../d';

import { Ability } from './ability';
import {
  abilitiesDescription,
  cardAbilities,
  cardTypeNames,
  descriptionPlateTextures,
  getAbilityDescriptionTextStyles,
  plateCostTextStyle,
  plateNameTextStyle,
} from './collection.model';
import { getSlotPrice } from './getSlotPrice';

export class DescriptionPlate extends Container {
  constructor(level: CardLevel, type: CardClass) {
    super();
    const slotId = (level + type) as SlotId;
    const abilityConfig = cardAbilities[slotId];

    this.addChild(this.getPlate(level));
    this.addChild(this.getName(type));
    this.addChild(this.getPrice(slotId));

    if (abilityConfig) {
      this.addChild(this.getAbilitiesContainer(abilityConfig));
    }
  }

  private getPlate(level: CardLevel): Container {
    const plate = new Sprite(Loader.shared.resources[descriptionPlateTextures]?.spritesheet?.textures[level]!);
    plate.width = 320;
    plate.height = 488;

    return plate;
  }

  private getName(type: CardClass): Container {
    const name = new TextField(i18n.t(cardTypeNames[type]), 405, 113, plateNameTextStyle);
    name.text.y = 55;
    this.alignToCenter(name.text);
    return name.getText();
  }

  private getPrice(slotId: SlotId): Container {
    const price = new TextField(getSlotPrice(slotId), 100, 38, plateCostTextStyle);
    price.text.y = 523;
    this.alignToCenter(price.text);
    return price.getText();
  }

  private getAbilitiesContainer(abilityConfig: ICardAbility[]): Container {
    const abilitiesContainer = new Container();
    const ability = new Ability(abilityConfig);
    const description = this.getAbilityDescriptions(abilityConfig);

    abilitiesContainer.y = 155;

    description.y = 90;
    this.alignToCenter(ability);
    abilitiesContainer.addChild(ability, description);

    return abilitiesContainer;
  }

  private getAbilityDescriptions(abilityConfig: ICardAbility[]): Container {
    const abilityDescriptionContainer = new Container();

    abilityConfig.forEach((ability, index) => {
      const description = new MultiStyleText(
        i18n.t<string>(abilitiesDescription[ability.pattern], {
          value: ability.value,
        }),
        getAbilityDescriptionTextStyles(ability.pattern),
      );

      description.y = index === 1 ? index * 40 : index * 70;
      this.alignToCenter(description);
      abilityDescriptionContainer.addChild(description);
    });

    return abilityDescriptionContainer;
  }

  private alignToCenter(container: Container): void {
    container.x = (this.width - container.width) / 2;
  }
}
